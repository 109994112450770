/******* VIEWCART *********/
/* Variables for Viewcart page */
/* Positioning remove and Auto Replenishment */
$dist-ruler: 25px;

.viewcart {
  .checkout-panel {
    /* Shopping Cart */
    &--viewcart {
      border-bottom: none;
      overflow: visible;
      .cart-items {
        &__item {
          border-top: 1px solid $color-light-gray;
          position: relative;
          margin: 15px 0;
          padding: 10px 16px 0;
          @include breakpoint($landscape-up) {
            @include swap_direction(padding, 25px 0 35px 5px);
            margin: 0;
          }
          &.product {
            @include breakpoint($landscape-up) {
              min-height: auto;
            }
          }
          > div {
            float: left;
          }
          &--desc,
          &--price,
          &--qty,
          &--total,
          &--replenishment {
            padding-#{$ldirection}: 2%;
            width: 78%;
            margin-top: 7px;
            @include breakpoint($landscape-up) {
              padding-#{$ldirection}: 0;
            }
          }
          &--thumb {
            width: 22%;
            @include breakpoint($small-down) {
              min-height: 130px;
            }
            img {
              display: inline-grid;
              word-wrap: break-word;
              width: 100%;
              @include breakpoint($landscape-up) {
                width: auto;
              }
            }
          }
          &--qty {
            form {
              position: absolute;
              #{$rdirection}: 16px;
              top: 32%;
              width: auto;
              @include breakpoint($landscape-up) {
                position: static;
                top: inherit;
                #{$rdirection}: inherit;
              }
              @include breakpoint($med-small-down) {
                top: 60px;
              }
            }
            .select-box {
              &__options,
              &__label {
                min-width: 77px;
              }
            }
          }
          &--product-name {
            font-weight: bold;
            line-height: normal;
            text-transform: uppercase;
          }
          .egift_cart {
            font-weight: normal;
          }
          .egiftcard_links {
            line-height: normal;
            letter-spacing: 0.05em;
            text-decoration: underline;
            text-transform: uppercase;
          }
          &--desc {
            line-height: 21px;
            width: 68%;
            word-wrap: break-word;
            @include breakpoint($landscape-up) {
              padding-#{$rdirection}: 2%;
              width: 33%;
              padding-bottom: 30px;
            }
          }
          &--total {
            padding: 15px 2% 30px;
            width: 40%;
            @include breakpoint($landscape-up) {
              padding: 0;
            }
            @include breakpoint($med-small-down) {
              padding: 15px 0 0;
            }
          }
          &--product-price {
            text-decoration: line-through;
          }
          &--points {
            color: $color-gray;
            display: none;
            margin-top: 5px;
          }
          &--replenishment {
            @include breakpoint($landscape-up) {
              width: 50%;
            }
            &__description {
              .overlay-link {
                color: $color-gray;
                display: inline-block;
                margin-bottom: 5px;
              }
            }
            &__notice {
              font-size: 12px;
              line-height: get-line-height(12px, 15px);
              margin-top: 5px;
            }
            .select-box {
              &__options,
              &__label {
                min-width: 100%;
                @include breakpoint($landscape-up) {
                  min-width: 195px;
                }
              }
            }
          }
          &--remove-product {
            a {
              cursor: pointer;
              position: absolute;
              #{$rdirection}: 31px;
              top: 7px;
              .icon--close:before {
                font-weight: bold;
                font-size: 10px;
              }
              &:hover {
                text-decoration: none;
              }
              @include breakpoint($landscape-up) {
                bottom: 60px;
                top: inherit;
                #{$rdirection}: inherit;
                line-height: normal;
                letter-spacing: 0.05em;
                text-decoration: underline;
                text-transform: uppercase;
              }
            }
          }
          &--error--out-of-stock {
            p {
              position: relative;
              margin-top: 10px;
              margin-bottom: 0;
              line-height: 15px;
              @include breakpoint($landscape-up) {
                margin-top: 10px;
              }
            }
          }
          &--promo-message {
            margin-top: 5px;
          }
          .cart_item__error--hazmat {
            p {
              @include breakpoint($landscape-up) {
                margin-top: 10px;
                width: 50%;
                float: right;
                line-height: 16px;
                &.special_item_sku {
                  width: 57%;
                }
              }
            }
          }
          .view-detail {
            &__link {
              @include breakpoint($landscape-up) {
                text-decoration: underline;
              }
            }
            &__content {
              display: none;
            }
          }
          .cart-items {
            &__details {
              width: 78%;
              > div {
                float: #{$ldirection};
              }
            }
          }
        }
        @include breakpoint($landscape-up) {
          &__header--item {
            color: $color-gray;
            float: left;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 9px;
          }
          &__products {
            width: 50%;
          }
          &__price {
            width: 18%;
          }
          &__qty {
            width: 13%;
          }
          &__total {
            padding-#{$ldirection}: 52px;
            width: 19%;
          }
          &__item {
            &--thumb {
              margin-#{$rdirection}: 2%;
              width: 15%;
            }
          }
        }
      }
    } /* End viewcart */
    /* Checkout Buttons Content */
    .checkout-buttons-content {
      padding: 16px;
      text-align: center;
      @include breakpoint($landscape-up) {
        margin: 15px 0 56px;
        padding: 0;
      }
      &__container {
        @include breakpoint($landscape-up) {
          float: right;
        }
      }
      &__item--choose-samples {
        display: none;
      }
      .continue-shopping,
      .continue-checkout,
      .or-text,
      .paypal-checkout {
        margin-bottom: 10px;
        @include breakpoint($landscape-up) {
          float: left;
          margin-bottom: 0;
        }
      }
      .continue-shopping {
        font-weight: bold;
        display: inline-block;
        margin-bottom: 40px;
        &.bottom {
          margin: 20px 0 0;
        }
        @include breakpoint($landscape-up) {
          font-weight: normal;
          margin: 10px 0 0;
          letter-spacing: 1px;
        }
      }
      .or-text {
        background: $white;
        color: $color-gray;
        text-transform: uppercase;
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          padding: 13px 24px 0;
        }
        &__wrap {
          border-bottom: 1px solid $color-gray;
          font-size: 15px;
          line-height: 0.1;
          margin: 10px 0 20px;
          @include breakpoint($landscape-up) {
            border-bottom: none;
          }
        }
      }
      .paypal-checkout {
        background: $white;
        border: 1px solid $color-off-black;
        display: block;
        padding: 0 10px;
        img {
          width: 98px;
        }
        @include breakpoint($landscape-up) {
          background: transparent;
          border: none;
          padding: 0;
          img {
            width: auto;
          }
        }
      }
      .continue-checkout {
        background: $color-off-black;
        color: $white;
        width: 100%;
        @include breakpoint($landscape-up) {
          height: 36px;
          line-height: 36px;
          min-width: 110px;
          padding: 0;
          width: auto;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .apple-pay-checkout {
        border: 1px solid $current-gray;
        border-radius: 4px;
        height: 50px;
        margin-bottom: 10px;
        .apple-pay-button {
          display: inline-block;
          width: 60px;
          height: 49px;
          background: url('/media/images/cart_enhancement/aveda_applepay_logo.png') no-repeat center;
        }
        .apple-pay-button-black {
          background-image: -webkit-named-image(apple-pay-logo-white);
          background-color: $black;
        }
        .apple-pay-button-white {
          background-image: -webkit-named-image(apple-pay-logo-black);
          background-color: $white;
        }
        .apple-pay-button-white-with-line {
          background-image: -webkit-named-image(apple-pay-logo-black);
          background-color: $white;
          border: 0.5px solid $black;
        }
        &:hover {
          background-color: $current-half-white;
          cursor: pointer;
        }
      }
      .paypal-smart-button {
        display: flex;
        column-gap: 8px;
        height: 50px;
        margin-bottom: 8px;
        width: 100%;
        &.paypal-loading {
          background: $white url('/media/images/ajax-loading.gif') no-repeat center;
          height: 48px;
        }
      }
    } /* End Checkout Buttons Content */
    /* Promo Banner */
    &--promo {
      padding: 0;
    }
    /* Promo BCA Donation */
    &--promo-bca,
    &--promo-earth_month,
    &--promo-salon_spa {
      background: $color-light-linen;
      border-bottom: none;
      margin-bottom: 30px;
      padding: 50px 16px;
      margin-top: 35px;
      @include breakpoint($landscape-up) {
        padding: 16px;
      }
      .promo-bca-content,
      .promo-earth_month-content,
      .promo-salon_spa-content {
        > div {
          float: left;
          width: 50%;
        }
        header {
          font-size: 19px;
          margin: 0;
          @include breakpoint($landscape-up) {
            margin: 15px 0 0;
          }
          h2 {
            margin: 0;
            @include breakpoint($landscape-up) {
              margin: 0 0 0.5em;
            }
          }
        }
        &__description {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 5px;
        }
        &__details {
          padding-#{$ldirection}: 10px;
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 5px;
          }
        }
        &__logo {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
        &__button {
          @include button-add-to-bag;
        }
        .donation-amounts {
          float: left;
          width: 100%;
          &__amount {
            input {
              #{$ldirection}: -9999px;
              position: absolute;
              &[type='radio'] {
                ~ label:before {
                  content: '';
                }
                &:checked ~ label {
                  background: $color-off-black;
                  color: $white;
                  &:before {
                    content: '';
                  }
                }
              }
            }
          }
          &__label {
            @include swap_direction(margin, 0 8px 10px 0);
            border: 1px solid $color-off-black;
            display: inline-block;
            font-size: 14px;
            height: 32px;
            float: left;
            padding: 0 5px;
            width: auto;
            line-height: get-line-height(14px, 31px);
            @include breakpoint($landscape-up) {
              text-align: center;
              letter-spacing: 0.05em;
              width: auto;
              padding: 0 5px;
              line-height: get-line-height(14px, 31px);
            }
          }
        }
      }
    } /* End Promo BCA */
    &__top-viewcart-buttons {
      border: none;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .link.bottom {
        display: none;
        padding: 0;
      }
    }
    &__bottom-viewcart-buttons {
      .link.top {
        display: none;
      }
    }
    .continue-shopping {
      text-transform: uppercase;
      text-decoration: underline;
    }
    .messages,
    .signin-to-see-cart {
      padding: 0 16px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      a {
        text-decoration: underline;
      }
    }
    .pp_promo {
      background-color: $color-light-linen;
      margin: 10px 0;
      &__message {
        margin: 0;
        padding: 15px;
        a {
          text-decoration: underline;
        }
      }
    }
    .pp_member_promo {
      background-color: $color-light-linen;
      margin: 10px 0;
      &__message {
        margin: 0;
        padding: 15px;
        a {
          text-decoration: underline;
        }
      }
    }
    &__content {
      &--terms {
        clear: both;
        padding-top: 10px;
        text-align: left;
        @include breakpoint($landscape-up) {
          text-align: right;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
} /* End Viewcrt */
