#index .checkout-single-page {
  .checkout__content {
    .checkout-panel {
      &--pure_privilege {
        display: none;
        &.display,
        &.edit,
        &.finished {
          display: block;
        }
        &.display,
        &.finished {
          .checkout-panel__content {
            display: none;
          }
        }
        .single-message {
          &:first-child {
            padding-top: 0;
          }
        }
      }
      .new-or-returning-content {
        display: none;
      }
      &--new-account {
        @include breakpoint($landscape-up) {
          margin-right: 12px;
        }
        &.has-guestcheckout-message {
          .guest-checkout-message {
            margin-bottom: 7px;
          }
        }
      }
      //Separator for Mobile
      &__separator {
        @include breakpoint($landscape-up) {
          display: none;
        }
        &--border {
          display: inline-block;
          width: 45%;
          border-bottom: 1px solid $color-light-gray;
          margin: 0;
        }
        &--text {
          display: inline-block;
          margin: 0;
          position: relative;
          text-transform: uppercase;
          top: 4px;
          color: $color-light-grey-text;
          width: 7%;
          text-align: center;
        }
      }
      &--new-account,
      &--return-user {
        padding: 0;
        border-bottom: none;
        header {
          @include breakpoint($landscape-up) {
            min-height: 85px;
          }
          h3 {
            @include breakpoint($landscape-up) {
              span {
                display: block;
              }
            }
          }
        }
        .form-item {
          &.password {
            @include breakpoint($landscape-up) {
              margin-bottom: 20px;
            }
          }
        }
        .submit {
          width: 100%;
          padding: 0;
        }
        input[type='submit'] {
          letter-spacing: 1px;
          font-weight: bold;
          background: $color-off-black;
          color: $white;
          @include breakpoint($landscape-up) {
            background: none;
            color: $color-off-black;
            border: 1px solid $color-off-black;
          }
          &:hover {
            background: $color-off-black;
            color: $white;
          }
        }
        @include breakpoint($landscape-up) {
          float: left;
          width: 50%;
          padding: 0;
          min-height: 330px;
        }
      }
      &--return-user {
        @include breakpoint($landscape-up) {
          width: 48%;
        }
        &.has-guestcheckout-message {
          .password {
            padding-top: 9px;
          }
        }
      }
      .return-user-content {
        .forgot-password {
          text-transform: uppercase;
          height: auto;
          &__note {
            display: block;
          }
          .error_messages {
            margin-top: 10px;
            display: inline-block;
            @include breakpoint($landscape-up) {
              margin-top: 0;
            }
          }
          @include breakpoint($landscape-up) {
            padding-bottom: 0;
          }
          a {
            @include anchorlink;
          }
        }
      }
      &--sign-in-main.finished {
        .checkout-panel {
          &__header {
            @include clearfix;
          }
          &__heading {
            float: left;
          }
        }
        .checkout-order-date {
          &__header {
            font-size: 15px;
            font-weight: bold;
            text-transform: none;
            float: right;
            @include breakpoint($landscape-up) {
              margin-bottom: 2px;
              line-height: 36px;
            }
          }
          &_content {
            float: right;
          }
        }
      }
    }
  }
}
