.appt-book.services {
  .appt-book-section {
    .appt-book-section-content {
      .services-applications {
        &.accordion-enable {
          margin-top: 0;
          border-bottom: 1px solid #392720;
          padding-bottom: 40px;
        }
        & > h2.section-header {
          p {
            margin: 28px auto 21px;
          }
          .accordion-hr {
            max-width: 951px;
            margin: 5px auto 0;
            display: block;
          }
        }
        h2 {
          p {
            margin: 28px auto 21px;
          }
        }
        @include breakpoint($medium-down) {
          &.accordion-enable {
            border-bottom: none;
            padding: 0;
            h2 {
              margin-top: 15px;
              p {
                font-size: 28px;
              }
            }
            .services-container-templates {
              .lesson-section-block {
                .section-header {
                  margin-top: 0;
                }
                .section-content {
                  border-bottom: transparent;
                  padding: 0;
                  .collapsible-block__title span {
                    padding: 0 15px;
                  }
                  .service {
                    padding: 22px 20px 18px;
                  }
                }
              }
            }
            .iconCaretDown:before {
              top: -6px;
            }
          }
        }
        .services-container-templates {
          .lesson-section-block {
            overflow: hidden;
            .section-header {
              font-size: 28px;
              font-weight: bold;
              margin-top: 20px;
            }
            .section-content {
              padding-top: 10px;
              margin: 20px 0 0 0;
              border-color: #cdcbc9;
              border-style: solid;
              border-width: 1px 0 1px 0;
              overflow: hidden;
            }
          }
          &.order-by-category {
            border-color: transparent;
            margin: auto;
            padding-top: 0;
          }
        }
      }
    }
  }
}

#appointment-book-sections {
  .confirm-container {
    #book-appt-fieldset {
      .appt-book-mobile-prefix {
        width: 30%;
        float: left;
        border-right: none;
      }
      #registration-mobile-phone {
        width: 70%;
        float: left;
      }
      .registration-mobile-phone {
        .label {
          margin-left: 30%;
          float: left;
        }
      }
    }
  }
}

.appt-book {
  .my-appointments {
    .appointment-details__item {
      .services-name span.price {
        display: none;
      }
    }
  }
  &.confirmation {
    .confirmation-container__item {
      .content__item--name {
        .price {
          display: none;
        }
      }
    }
  }
}
