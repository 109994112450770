/* PROMO PANEL */
/// Promo panel top
.panel--promo {
  margin-bottom: 75px;
  display: none; // hide for mobile
  @include breakpoint($medium-up) {
    // show above mobile
    display: block;
  }
  &.panel {
    border: none;
    .banner {
      margin-bottom: 0.4em;
      padding: 0.1em;
    }
  }
  &__img,
  img {
    height: 100px; // take out for live
    width: 100%;
    border: $cart-line;
  }
}
/* OFFER CODE PANEL */
#offer_code .button-primary {
  margin-left: 10px;
}

#offer-code-panel {
  input {
    width: auto;
  }
  .checkout__offer-code__wallet-item {
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px;
    margin: 0 0 10px;
    .checkout__offer-code__wallet-code-applied,
    .checkout__offer-code__wallet-remove {
      display: none;
    }
    .checkout__offer-code__wallet-code-unapplied {
      font-size: 0.8em;
    }
    .checkout__offer-code__wallet-code__in-cart,
    .checkout__offer-code__wallet-code {
      font-weight: bold;
    }
    // @TODO cleanup to extend silent class % NOT .
    .checkout__offer-code__wallet-apply {
      @extend .button;
      @extend .checkout__button;
    }
    &.is-applied {
      .checkout__offer-code__wallet-description {
        font-size: 0.8em;
      }
      .checkout__offer-code__wallet-code-unapplied,
      .checkout__offer-code__wallet-apply {
        display: none;
      }
      .checkout__offer-code__wallet-code-applied {
        display: block;
      }
      .checkout__offer-code__wallet-remove {
        display: inline;
      }
    }
  }
}
/* BCA PROMOTION */
.donation_amounts {
  padding-left: 0.1em;
  margin-bottom: 1em;
}

.donation_amount {
  display: inline;
  margin: 0.6em 0.6em 0 0;
}

.promo_bca_note {
  clear: both;
  margin: 1em 0 0;
}

.promo_bca_logo {
  width: 82px;
  height: 82px;
  float: right;
}

.column {
  &.right {
    .promo_bca_logo {
      display: none;
    }
    #promo-bca-panel .panel {
      border: 0;
      margin: 0;
    }
    .cart-item.donation .price {
      display: none;
    }
  }
  &.left #promo-bca-panel {
    border-width: 0 1px;
    margin: 0;
    .panel {
      width: 60%;
      margin: 1em;
    }
  }
}

.cart-item.donation {
  .product_subname {
    display: none;
  }
  .sub_line {
    display: none;
  }
}

.panel .donation_amount_label {
  display: inline;
  margin-right: 0.4em;
}

#promo-bca-panel .bca-logo {
  float: right;
}
