#index .checkout.checkout-single-page {
  .checkout-panel--registration {
    padding-bottom: 0;
    border-bottom: none;
    @include breakpoint($landscape-up) {
      padding: 0 10px;
    }
    .messages {
      .single-message:first-child {
        padding-top: 0;
      }
    }
    header {
      a.edit {
        display: none;
      }
    }
    &.collapsed {
      margin-bottom: 15px;
      header {
        h2 {
          margin: 0;
        }
      }
    }
    &.finished {
      header {
        @include breakpoint($landscape-up) {
          background: $color-light-linen;
          margin-bottom: 15px;
        }
        a.edit {
          display: inline;
          @include breakpoint($landscape-up) {
            float: right;
            display: inline;
            position: relative;
            top: 4px;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .registration-content {
      &__heading {
        @include checkout-index-header-mobile;
        @include breakpoint($landscape-up) {
          @include checkout-index-header-pc();
        }
      }
      &__pre {
        ul {
          margin-top: 15px;
          li {
            list-style-type: circle;
            margin: 0 0 10px 17px;
          }
        }
      }
      .accepted-privacy-policy {
        height: auto;
        .terms_disclaimer {
          display: none;
        }
      }
      .create-account {
        height: auto;
        width: 100%;
        @include breakpoint($landscape-up) {
          text-align: right;
          input {
            width: 80%;
            float: left;
          }
        }
        .continue-as-guest {
          line-height: 35px;
          text-align: center;
          width: 100%;
          display: inline-block;
          text-decoration: underline;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            width: auto;
            font-size: 14px;
          }
        }
      }
      &__create-account {
        @include breakpoint($landscape-up) {
          float: right;
          width: 48%;
        }
      }
      .checkout-panel--email-and-sms-promotions {
        @include breakpoint($landscape-up) {
          clear: both;
          .sms-mobile-phone {
            width: 50%;
          }
        }
      }
      .checkout-registration {
        @include breakpoint($landscape-up) {
          margin-bottom: 15px;
          float: left;
          width: 45%;
        }
        .form-item {
          height: auto;
          margin-bottom: 0;
        }
        &__email-address {
          padding-bottom: 15px;
        }
        &__password {
          width: 100%;
          .note {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .email-promotions {
    .pc_email_promo_container {
      label.field_label {
        width: 92%;
      }
      a.link {
        margin-left: 23px;
      }
    }
  }
}
