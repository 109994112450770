#index .checkout.checkout-single-page {
  .checkout-panel--payment {
    margin-bottom: 20px;
    @include breakpoint($landscape-up) {
      border-bottom: none;
    }
    header {
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
    &.collapsed {
      header {
        margin-bottom: 15px;
      }
    }
    .form-item.select {
      .invalid_marker,
      .valid_marker {
        display: none;
      }
    }
    input,
    select {
      &:required:invalid:not(:checked).checkedEmpty {
        border: 1px solid $color-red;
      }
    }
    .payment-edit-content {
      padding: 0;
      &__heading {
        @include checkout-index-header-mobile;
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          padding: 0;
          @include shipping-sub-header();
        }
      }
      .alternate-address-links {
        &__label {
          display: inherit;
          margin-#{$ldirection}: 25px;
        }
      }
      .payment-container {
        p {
          display: inline;
        }
        .payment-control {
          height: auto;
          margin: 5px 0 0 0;
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 50%;
          }
          span {
            margin: 0 0 10px;
            float: left;
            min-height: 0;
            @include breakpoint($landscape-up) {
              margin-top: 0;
            }
          }
        }
        .saved-payments {
          .select-box {
            width: 60%;
          }
        }
        .address {
          padding: 0 10px;
          @include breakpoint($landscape-up) {
            padding: 0;
          }
          h3,
          h4 {
            @include checkout-index-header-mobile;
            @include breakpoint($landscape-up) {
              @include shipping-sub-header();
            }
          }
          a {
            @include anchorlink;
            margin-bottom: 0;
            height: auto;
          }
          .select-address {
            margin-bottom: 15px;
            border-bottom: none;
            padding: 0;
            .new-address {
              .form-item {
                width: 100%;
                &.default-shipping {
                  height: auto;
                  margin: 0;
                }
              }
              .address-form {
                .name-fields {
                  @include breakpoint($landscape-up) {
                    .first-name,
                    .last-name {
                      width: 49%;
                      float: left;
                    }
                    .first-name {
                      margin-right: 12px;
                    }
                  }
                }
                .addresses {
                  @include breakpoint($landscape-up) {
                    .form-item {
                      width: 100%;
                    }
                  }
                }
                .phone-1 {
                  @include breakpoint($landscape-up) {
                    width: 49%;
                  }
                }
                &__city-state-zip {
                  .city,
                  .postal-code {
                    width: 48%;
                    float: left;
                    @include breakpoint($landscape-up) {
                      width: 24%;
                      float: left;
                    }
                  }
                  .postal-code {
                    margin-right: 10px;
                    @include breakpoint($landscape-up) {
                      margin-right: 6px;
                    }
                  }
                  .state {
                    clear: both;
                    .select-box {
                      width: 100%;
                    }
                    @include breakpoint($landscape-up) {
                      margin-left: 13px;
                      width: 49%;
                      clear: none;
                      float: left;
                    }
                  }
                }
                &__country {
                  .select-country {
                    width: 100%;
                  }
                }
              }
            }
            div {
              @include clearfix;
              &.address-to-use {
                margin: 10px 0;
                height: auto;
                span {
                  &.address-to-use {
                    height: auto;
                    width: 250px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    float: left;
                    &:nth-child(2) {
                      width: 240px;
                    }
                  }
                }
              }
            }
            .existing-address {
              .address-menu-container {
                .address-options {
                  height: auto;
                  width: 45%;
                  margin-right: 15px;
                  min-height: 230px;
                  float: left;
                  @include breakpoint($landscape-up) {
                    width: 31%;
                    min-height: 200px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .continue-button-wrapper {
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          padding: 0;
          float: right;
          width: auto;
        }
        .submit {
          margin: 0;
          width: 100%;
        }
      }
      .form-container {
        padding: 0 10px;
        border-bottom: 1px solid $color-light-gray;
        @include breakpoint($landscape-up) {
          padding: 0;
          border-bottom: none;
        }
        .payment-form {
          margin-top: 20px;
          @include breakpoint($landscape-up) {
            margin-top: 15px;
          }
          .form-item {
            float: left;
            &.card-type {
              height: auto;
              margin: 15px 0 0 0;
              padding: 0;
            }
            &.card-number {
              width: 65%;
              margin-right: 15px;
            }
            &.do-not-save-payment {
              width: 100%;
              height: auto;
            }
            &.cvv {
              width: 30%;
              a {
                display: none;
              }
            }
            &.select {
              width: 48%;
              margin-left: 11px;
              &.expires-month {
                margin: 0;
                .no-placeholder & {
                  margin-top: 20px;
                  .label-content {
                    display: none;
                  }
                }
                .select-box {
                  @include breakpoint($landscape-up) {
                    width: 100%;
                  }
                }
              }
              &.expires-year {
                .select-box {
                  @include breakpoint($landscape-up) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      &__payment-type {
        @include breakpoint($medium-up) {
          &.mobile-hidden {
            display: block !important;
          }
        }
        span.payment-option {
          float: none;
          width: 100%;
          display: block;
          height: auto;
          @include breakpoint($landscape-up) {
            float: left;
            width: auto;
            margin-bottom: 5px;
          }
          label {
            float: left;
            &:before {
              line-height: 25px;
            }
            span.pp {
              margin-left: 10px;
              display: inline-block;
              vertical-align: top;
            }
            ul.related-media {
              float: right;
              li.cc {
                float: left;
                margin-right: 10px;
                &:first-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    &.finished,
    &.display {
      header {
        @include breakpoint($landscape-up) {
          background: $color-light-linen;
          margin-bottom: 15px;
        }
      }
      h3 {
        @include checkout-index-header-mobile;
      }
      .checkout-panel__content {
        h3,
        h4 {
          @include breakpoint($landscape-up) {
            @include shipping-sub-header();
          }
        }
      }
      .payment-display-content {
        padding-bottom: 10px;
        @include breakpoint($landscape-up) {
          float: left;
          width: 45%;
          margin: 0 20px 20px 0;
        }
        &__subsection {
          > div {
            padding: 0;
          }
          @include breakpoint($landscape-up) {
            float: left;
            width: 45%;
          }
        }
        &__payment {
          @include breakpoint($landscape-up) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .no-placeholder & {
    .payment-form .form-item {
      &.expires-year {
        margin-top: 20px;
      }
    }
    .form-item.select.state {
      margin-top: 20px;
    }
  }
  .checkout-panel__salon {
    margin-top: 15px;
    .checkout-panel__heading--stylist {
      @include breakpoint($landscape-up) {
        padding-top: 10px;
      }
    }
  }
  .checkout-panel__pure-previlege {
    margin-bottom: 15px;
  }
}
