/* GIFTCARD */
#index .checkout.checkout-single-page {
  .payment-giftcard {
    margin-top: 10px;
    border-bottom: 1px solid $color-light-gray;
    @include breakpoint($landscape-up) {
      margin-top: 0;
      border-bottom: none;
    }
    h3 {
      @include checkout-index-header-mobile;
      padding: 0 10px;
      @include breakpoint($landscape-up) {
        padding: 0;
        @include shipping-sub-header();
      }
    }
    fieldset {
      display: none;
      padding: 0 10px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .form-item.has-giftcard {
        height: auto;
        margin-bottom: 15px;
      }
      .checkout_giftcard {
        .giftcart-note {
          @include subheader-content;
          font-size: 14px;
          @include breakpoint($landscape-up) {
            font-size: 16px;
          }
        }
        .form-item {
          clear: left;
          width: 100%;
          @include breakpoint($landscape-up) {
            clear: none;
            float: left;
            &.giftcard-number {
              margin-right: 10px;
              width: 49%;
            }
            &.giftcard-pin {
              width: 49%;
            }
            &.giftcard-apply,
            &.giftcard-balance {
              width: auto;
            }
            &.giftcard-apply {
              margin-left: 15px;
            }
          }
          a {
            @include continue-button;
          }
        }
      }
    }
  }
}

.giftcard-balance-overlay {
  padding: 0 10px;
  h3 {
    @include checkout-index-header-mobile;
    @include breakpoint($landscape-up) {
      @include checkout-index-header-pc();
      margin: 15px 0;
      border-bottom: 1px solid $color-light-gray;
    }
  }
}

.has-giftcard-text {
  margin-left: 0.5em;
}

.giftcart-balance {
  margin-top: 10px;
  font-weight: bold;
}

fieldset.fs.checkout_giftcard {
  margin-top: 0;
  max-width: none;
}
