.qas-submit-container {
  margin-bottom: 15px;
}

.form-item {
  margin-bottom: 15px;
}

.account-section__content {
  .address-controls a {
    text-decoration: underline;
  }
  .order-products {
    .cart-item__wrap {
      .cart-item__qty {
        padding-right: 0;
      }
    }
  }
}

.address-book-page__overlay {
  #cboxClose {
    right: 20px;
    top: 20px;
  }
  #qas {
    .error {
      color: red;
    }
  }
  .form-item {
    &.qas-submit-container,
    &.qas_submit_container {
      input {
        background: #392720 !important;
        color: white;
      }
    }
    &.title {
      span.label-content {
        display: none;
      }
    }
  }
}

.past-purchases__content {
  .tracking-link {
    &.past-purchases__order-status {
      position: relative;
      .tracking_disclaimer {
        display: none;
      }
      .track_order_link:hover .tracking_disclaimer {
        display: block;
        background-color: $white;
        color: $color-off-black;
        border: 1px solid $color-off-black;
        border-radius: 6px;
        font-size: 11px;
        width: 160px;
        text-transform: unset;
        position: absolute;
        bottom: 20px;
        padding: 3px;
        z-index: 1;
      }
    }
  }
}

.account-page {
  .orders-list {
    .orders-list__table {
      position: relative;
      .tracking_disclaimer {
        display: none;
      }
      .order-detail-item-link:hover ~ .tracking_disclaimer {
        display: block;
        background-color: $white;
        color: $color-off-black;
        border: 1px solid $color-off-black;
        border-radius: 6px;
        font-size: 11px;
        width: 270px;
        text-transform: unset;
        position: absolute;
        max-width: 400px;
        z-index: 999;
      }
    }
  }
}

.product-full {
  .product-sku-price.has-sale .product-price-points-display {
    width: 90%;
    margin-left: 0;
  }
  .product-sku-price__value {
    display: inline;
    clear: both;
    text-align: left;
  }
  .sku-menu__container {
    .select-box__options.open {
      z-index: 99;
    }
  }
  .product-sku-unit-price__value {
    width: 78%;
    float: left;
  }
  .product-price-points-display {
    text-align: left;
  }
}

.product-quickshop__details {
  .product-sku-unit-price__value {
    float: left;
    width: 78%;
  }
  .product-price-points-display {
    text-align: left;
  }
}

.profile-page__content {
  border-bottom: 1px solid $color-light-gray;
  padding-bottom: 20px;
  input[type='checkbox'] ~ label {
    margin: 0 0 10px 10px;
    float: left;
    width: 96%;
  }
  @include breakpoint($landscape-up) {
    .optional-info__item {
      .form-item {
        width: 20%;
      }
    }
  }
}

.profile-page__content,
.new-account__fieldset,
.signin-overlay {
  .newsletter-info__fieldset {
    .profile-page & {
      width: 100%;
      margin-#{$rdirection}: 0;
      @include breakpoint($landscape-up) {
        width: 46%;
        margin-#{$rdirection}: 2.6%;
      }
    }
  }
  #providing-email {
    text-align: justify;
    width: 90%;
  }
  .sms-promotions {
    .sms-info__prefix {
      margin: 70px 0 0 25px;
      span {
        border: 1px solid;
        padding: 10px;
      }
    }
    .sms-info__number-field {
      @include breakpoint($landscape-up) {
        width: 20%;
      }
      position: absolute;
      margin: -28px 0 0 90px;
      input[type='tel'] {
        height: 36px;
      }
    }
    .sms-mobile-terms {
      margin: 20px 0 20px;
      .mobile-terms {
        text-transform: inherit;
        font-weight: normal;
        text-align: justify;
        margin-bottom: 10px;
      }
    }
  }
  .pure-privilege-info {
    .pp-link {
      &__email-field,
      &__smsoptin-field {
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .account-section__content {
    padding-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-full__quote {
    width: 30%;
  }
  .account-section__content {
    padding-right: 15px;
  }
}

.new-account__fieldset {
  .sms-promotions {
    .sms-info__prefix {
      display: block;
      margin-top: 20px;
    }
  }
}

#manual-address-fields {
  .country_container {
    #form--address_billing--field--COUNTRY_ID {
      .js-select-box-options {
        div:first-child {
          display: none;
        }
      }
    }
  }
}

.signin-overlay {
  .sms-promotions {
    margin: 0 0 10px 0;
    line-height: 1.375;
    .sms_promo_label {
      width: 95%;
    }
    .sms-info__prefix {
      span {
        border: 1px solid;
        margin-top: 10px;
        padding: 0 10px 0 10px;
        display: inline-block;
        line-height: 2.45em;
      }
    }
    .sms-info__number-field {
      margin-top: -36px;
    }
  }
}

.giftcard-checkbalance-page {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .account-page__content {
      margin-top: 3em;
    }
  }
  ul.error {
    margin-bottom: 15px;
    color: red;
  }
}

.payment-delete-confirm {
  text-align: center;
  .gc_header {
    margin-top: 30px;
  }
}

.past-purchases {
  &-page__content {
    .past-purchases {
      .past-purchase {
        .product-item__name {
          min-height: inherit;
        }
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 100%;
}

#waitlist {
  .email_input,
  .align-r {
    float: left;
    display: inline-block;
    margin-right: 10px;
  }
}

.waitlist-form {
  padding: 35px 30px 30px 30px;
  .waitlist-form__email {
    height: 38px;
  }
  .waitlist-form__submit {
    padding: 0;
    height: 38px;
    top: 0;
  }
  .waitlist-tooltip {
    margin-top: 15px;
    .tooltiptext-over {
      max-width: 100%;
    }
  }
}

.signin-block {
  &__required {
    margin-bottom: 15px;
  }
}

.password-request-page,
.password-reset-page,
.password-sent-page {
  max-width: 1200px;
  .email-address {
    font-weight: bold;
  }
  .sent-info__text {
    .button {
      background: #392720;
      color: #fbf8f6;
      text-decoration: none;
    }
  }
}

.account-page .section-content span.product-sku-price2__value {
  text-decoration: line-through;
}
