.service-price-page-block {
  .tabbed-block__text p {
    max-width: 900px;
    margin: 0 auto;
  }
  .service-price-block {
    .tabbed-block__tabs {
      max-width: 100%;
      padding: 20px 0;
    }
    .tabbed-block__tabs a {
      text-transform: initial;
    }
    h3 {
      text-transform: initial;
    }
  }
  .service-content {
    width: 80%;
    margin: 0 auto;
    .tabbed-block__sub_title {
      float: left;
      width: 50%;
      border-bottom: 2px solid $color-off-black;
      margin-bottom: 20px;
      padding-bottom: 25px;
    }
    .tabbed-block__sub_title h2 {
      font-weight: bold;
    }
  }
  .service-book-split__text {
    width: 33%;
    display: inline-block;
    float: right;
    @include breakpoint(($portrait-up) ($medium-down)) {
      width: 45%;
    }
    p {
      margin: 7px 0 0 0;
    }
  }
  .service-book-split__cta {
    width: 66%;
    display: inline-block;
    float: left;
    @include breakpoint(($portrait-up) ($medium-down)) {
      width: 50%;
    }
  }
  .services-content-block-left {
    width: 47%;
    text-align: justify;
    float: left;
    margin-bottom: 20px;
    .services-content-block__image {
      padding: 20px 0;
    }
  }
  .services-content-block-right {
    float: right;
    width: 47%;
    text-align: justify;
    margin-bottom: 20px;
    .services-content-block__text {
      .label-content {
        width: 50%;
        float: left;
        margin-bottom: 15px;
      }
      .label-price {
        text-align: right;
        margin-bottom: 15px;
      }
    }
    .service-content-block-split__text p {
      text-transform: initial;
    }
    .service-content-block-split__cta a {
      text-transform: initial;
    }
    &.service-book-split {
      float: left;
      width: 50%;
      text-align: right;
      border-bottom: 2px solid $color-off-black;
      margin-bottom: 20px;
      padding-bottom: 23px;
      @include breakpoint(($portrait-up) ($medium-down) (orientation portrait)) {
        padding-bottom: 18px;
      }
      @include breakpoint(($portrait-up) ($medium-down) (orientation landscape)) {
        padding-bottom: 23px;
      }
    }
  }
}

.institute-menu-bottom {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  border-top: 2px solid $color-off-black;
  .title-menu-bottom {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content-menu-bottom {
    .link-block-split__text {
      text-align: center;
      padding: 10%;
      font-weight: bold;
    }
    .img-block-split__image {
      margin: 0 20px;
    }
  }
}

.services-table {
  td {
    padding: 15px;
    p {
      margin-bottom: 0;
    }
  }
  th {
    padding: 15px;
    text-transform: uppercase;
    p {
      margin-bottom: 0;
    }
  }
  @include breakpoint(($portrait-up) ($medium-down)) {
    width: 94%;
    margin-left: 3%;
    ~ p {
      margin-left: 3%;
      margin-right: 3%;
    }
  }
}

//Joint the Conversation Page Styles
.join-conversation {
  text-align: center;
  padding: 0 18px;
  max-width: 900px;
  margin: 0 auto;
  &-head {
    font-weight: 700;
    font-size: 38px;
    line-height: 1;
    padding-top: 25px;
  }
  &-subtitle {
    font-size: 16px;
    line-height: 22px;
    padding-top: 7px;
    margin-bottom: 0;
  }
  &__social-icons {
    text-align: center;
    padding-bottom: 20px;
    a {
      text-decoration: none;
      span {
        font-size: 24px;
        margin-right: 25px;
      }
      &:last-child {
        span {
          margin-right: 0;
        }
      }
    }
  }
}

.olapic {
  .olapic-wall-widget {
    .social-gallery__content & {
      margin-left: 100px;
    }
    margin-left: 50px;
    .olapic-wall-header {
      .olapic-top {
        .olapic-tools {
          display: none;
        }
      }
    }
    .olapic-wall {
      @include breakpoint(($portrait-up) ($medium-down) (orientation portrait)) {
        margin-left: 20px;
      }
      @include breakpoint(($portrait-up) ($medium-down) (orientation landscape)) {
        margin-left: 65px;
      }
    }
    .olapic-loadmore {
      background: $color-off-black;
      color: $white;
      border-color: $white;
      z-index: 99;
    }
    .olapic-wall-type-video {
      i {
        z-index: 99;
      }
    }
  }
}
