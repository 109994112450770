/* Common styles for social login section */
.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

@mixin disclaimer {
  display: flex;
  margin-top: 20px;
  text-align: left;
}

.fb-overlay-container {
  .disclaimer {
    @include disclaimer;
    text-align: left;
    line-height: 20px;
  }
  #facebook_signin {
    padding-left: 0;
    text-align: left;
    line-height: 20px;
  }
  width: 100%;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  display: inline-flex;
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.social-login {
  &__divider {
    width: 100%;
  }
  &__container {
    label {
      padding-left: 10px;
    }
    .disclaimer {
      @include disclaimer;
      .fb-disclaimer {
        text-align: left;
      }
    }
    #facebook_signin {
      display: flex;
      padding-left: 0;
      padding-top: 10px;
      .social-login__opt-in-label {
        margin-#{$ldirection}: 0;
      }
    }
  }
  &__email-opt-in,
  .disclaimer {
    label {
      text-transform: capitalize;
      font-weight: 400;
      text-align: left;
      a {
        text-decoration: underline;
      }
    }
  }
  .tooltip {
    text-align: left;
    margin-top: 10px;
  }
}

.social-login,
.social-info {
  .tooltiptext-over {
    max-width: 100%;
    a {
      text-decoration: underline;
    }
  }
}
/* Gnav signin  page styling */
.signin-overlay {
  .social-login {
    &__container {
      padding-left: 30px;
    }
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      padding-top: 10px;
    }
  }
}
/* Account profile page styling */
.profile-page {
  .social-info {
    border-top: 0;
    @include breakpoint($landscape-up) {
      margin-#{$ldirection}: 29%;
    }
    &__connect {
      padding: 0;
    }
    .fb_text {
      vertical-align: top;
    }
    &__content p {
      margin-top: 10px;
      a {
        text-decoration: underline;
      }
    }
    .social-login {
      &__terms {
        margin-bottom: 0;
        padding: 0;
        label {
          text-transform: capitalize;
          font-weight: 400;
        }
      }
    }
    .tooltip {
      margin-top: 10px;
    }
  }
}
/* Order confirmation page styling */
#confirmation-page {
  .social-login {
    margin-bottom: 20px;
    &__container {
      padding: 0;
    }
    &__divider {
      display: none;
    }
    &__title,
    &__info {
      display: block;
      text-align: left;
      margin-top: 10px;
    }
    &__email-opt-in,
    .fb-overlay-container {
      text-align: left;
    }
  }
}
/* my-appointments page styling */
.my-appointments {
  .social-login {
    &__container label {
      padding-left: 20px;
    }
    .disclaimer,
    .tooltip,
    &__email-opt-in {
      margin-left: 20px;
      @include breakpoint($medium-up) {
        margin-left: 0;
      }
    }
  }
}
/* overwritting existing styles */
#index {
  .pg_wrapper {
    .social-login {
      &__container {
        #facebook_signin {
          padding-left: 0;
        }
      }
    }
  }
}
/* Account Index Page */
#signin {
  .sign-in-page {
    .social-login {
      &__container {
        @include breakpoint($medium-up) {
          padding-left: 17%;
        }
      }
    }
  }
}
