$font: 'MetaMediumRoman';

@mixin anchorlink {
  border-bottom: 1px solid $color-off-black;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
  &:hover {
    border-bottom: 1px solid $color-off-black;
    text-decoration: none;
  }
}

@mixin continue-button {
  letter-spacing: 1px;
  background: $color-off-black;
  color: $white;
  width: 100%;
  &:hover {
    background: $color-off-black;
    color: $white;
  }
  @include breakpoint($landscape-up) {
    width: auto;
  }
}

@mixin checkout-index-header-mobile {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 15px 0;
}

@mixin subheader-content {
  font-size: 16px;
  line-height: get-line-height(16px, 20px);
  margin-bottom: 15px;
}

.checkout {
  @include clearfix;
  position: relative;
  margin: 20px auto 40px;
  max-width: $max-width;
  z-index: 1;
  font-size: 14px;
  &__header {
    position: relative;
    text-transform: uppercase;
    width: 100%;
  }
  &__heading {
    font-size: 36px;
    text-align: center;
    margin-bottom: 0.48em;
    @include breakpoint($landscape-up) {
      font-size: 38px;
      text-align: left;
    }
  }
  &__banner {
    &--placeholder-tout {
      background-color: $light-gray;
      margin-bottom: 20px;
      padding: 2em;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &__content {
    .review-panel--header {
      display: none;
    }
    &.bopis_enabled {
      @include breakpoint($landscape-up) {
        border-top: 0;
      }
      .checkout__header {
        display: flex;
        @include breakpoint($medium-down) {
          padding: 0 10px;
          .checkout__heading {
            font-size: 26px;
            text-align: left;
          }
          .continue-shopping {
            margin-top: 10px !important;
          }
        }
        .checkout__heading {
          flex: 1;
        }
        .continue-shopping {
          margin-top: 20px;
          &:after {
            @include svg-icon-inline-mask('caret--right');
            content: '';
            background-color: $color-off-black;
            width: 11px;
            height: 11px;
            font-size: 11px;
            margin-top: -2px;
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      float: left;
      width: 64%;
      border-top: 1px solid $color-light-gray;
    }
    fieldset {
      width: auto;
      max-width: none;
      margin-#{$rdirection}: 0;
      &.fs {
        max-width: 45em;
        width: 100%;
      }
    }
    .address-options {
      @include breakpoint($landscape-up) {
        width: 33.3333333%;
      }
      &__address-container {
        min-height: 120px;
      }
      &__select-this-address {
        line-height: 18px;
        padding: 10px;
        // Hide --selected span at first
        .select-label--selected {
          display: none;
        }
        // If it's the the selected address, show --selected and hide the normal span
        &.selected {
          .select-label,
          .message {
            display: none;
            &--selected {
              display: inline-block;
            }
          }
        }
      } // &__select-this-address
    } // .address-options
  } // &__content
  &__footer {
    @include clearfix;
    clear: both;
  }
  &__online-contact-panel {
    @include clearfix;
    padding-bottom: 8px;
    p {
      float: left;
      padding: 0 10px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    .cs-contact__nav {
      float: right;
      ul {
        li {
          float: left;
          margin-#{$ldirection}: 10px;
          img {
            @include swap_direction(padding, 0 5px 3px 0);
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  .checkout__banner {
    display: none;
  }
  .checkbox--afterpay {
    @include breakpoint($portrait-up) {
      display: inline-flex !important;
      height: auto;
      bottom: 14px;
    }
  }
  .afterpay-option-checkbox {
    display: inline-flex;
    img.label-logo {
      width: 29%;
      position: relative;
      top: -1px;
      @include breakpoint($medium-up) {
        width: 15%;
      }
    }
    .afterpay-learnmore {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 50%;
      background: $color-off-black;
      color: $white;
      margin-#{$rdirection}: 5px;
      font-size: 10px;
      font-family: $font;
      line-height: 1.4;
      text-transform: lowercase;
      text-align: center;
    }
    .purchase-agreement {
      text-decoration: underline;
    }
  }
} // .checkout

.checkout-panel {
  @include clearfix;
  position: relative;
  border-bottom: 1px solid $color-light-gray;
  &:last-child {
    border-bottom-width: 0px;
  }
  &:first-child {
    border-bottom-width: 0px;
    @include breakpoint($landscape-up) {
      border-top-width: 0px;
    }
  }
  &__header {
    position: relative;
    margin: 0.5em 0;
    width: 100%;
    font-weight: bold;
    .edit {
      float: right;
      margin-top: -2px;
      text-transform: uppercase;
    }
  }
  &__heading {
    @include swap_direction(margin, 0 0 0.5em 0);
    display: inline-block;
    padding: 0;
    text-transform: uppercase;
    .checkout_review_sidebar_subsection & {
      width: 100%;
      .edit-panel {
        float: right;
        text-decoration: underline;
        font-size: 14px;
        margin-top: 3px;
        font-weight: bold;
      }
    }
    .view-content-button {
      font-size: 12px;
      position: absolute;
      #{$rdirection}: 0;
      top: 35%;
    }
  }
  &__content {
    margin: 0;
  } // &__content
  .continue-button-wrapper {
    text-align: left;
  }
  .messages {
    padding: 0;
    color: $color-red;
    background-color: transparent;
    border-width: 0;
    &.error a {
      color: $color-red;
    }
    [id^='error_offer_criteria_met'] {
      color: $color-green;
    }
  }
  .single-message {
    &:first-child {
      padding-top: 20px;
    }
  }
  .warning-message {
    margin-bottom: 1em;
    color: $color-red;
    font-size: 1.2em;
    font-weight: bold;
  }
  .notice-message {
    color: $color-yellow;
  }
  // Panel loading animation
  .loading {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: white url(/media/images/ajax-loading.gif) no-repeat center center;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      content: '';
      display: inline-block;
      margin-#{$rdirection}: -0.25em;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      padding-top: 50px;
      width: 100px;
      vertical-align: middle;
    }
    // End of panel loading animation
  }
} // .checkout-panel
/* Sidebar */
.checkout__sidebar {
  margin: 11px;
  border-top: 1px solid $color-light-gray;
  @include breakpoint($landscape-up) {
    @include swap_direction(margin, 0 0 0 6%);
    float: right;
    width: 28.5%;
  }
  .checkout-panel {
    &__header {
      margin: 20px 0;
      @include breakpoint($landscape-up) {
        margin: 15px 0 24px;
      }
    }
    &__heading {
      margin: 0;
      padding: 0;
      font-size: 27px;
      &.mobile-heading {
        display: none;
      }
    }
    /* Offer code */
    &--offer-code {
      border-bottom: 1px solid $color-light-gray;
      padding-bottom: 20px;
      margin-bottom: 30px;
      .offer-code-content {
        &__wallet-description {
          display: inline-block;
          font-weight: bold;
          margin-bottom: 10px;
        }
        &__form-link {
          display: block;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }
    /* Past Purchases, Your Favorites and Recommended Products */
    &.accordion-wrapper {
      background: $color-light-linen;
      border-bottom: none;
      margin-bottom: 27px;
      padding: 10px 10px 5px;
      header {
        margin: 0;
        .checkout-panel__heading {
          cursor: pointer;
        }
      }
      .accordion-content {
        display: none;
        &.on-the-go-products-content,
        &.recommended-products-content {
          display: block;
        }
        p {
          &:first-child {
            margin-top: 10px;
          }
        }
        .product {
          border-bottom: 1px solid $color-light-gray;
          display: inline-block;
          margin: 10px 0;
          position: relative;
          padding-bottom: 20px;
          width: 100%;
          &:last-child {
            border-bottom: none;
          }
        }
        .shades-list {
          display: none;
        }
        .product-img {
          float: left;
          min-height: 100px;
          width: 32%;
          img {
            display: inline-grid;
            word-wrap: break-word;
          }
        }
        .details {
          float: right;
          width: 65%;
          .product-name {
            font-weight: bold;
            text-transform: uppercase;
          }
          .price {
            margin-top: 5px;
          }
          .product-size {
            margin-top: 5px;
          }
          .add-to-cart {
            margin-top: 10px;
            .button {
              @include button-add-to-bag;
            }
          }
        }
      }
    }
    /* Quick Links */
    &--links {
      header {
        @include breakpoint($landscape-up) {
          margin-bottom: 15px;
        }
      }
      .links-content {
        text-transform: uppercase;
        @include breakpoint($landscape-up) {
          text-decoration: underline;
        }
        p {
          font-size: 14px;
          margin: 0 0 5px;
          letter-spacing: 0.08em;
          a {
            text-decoration: underline;
          }
        }
      }
    }
    /* Need Help */
    &--need-help {
      border-top: 1px solid $color-light-gray;
      .need-help-content {
        &__item {
          a {
            display: inline-block;
            margin-bottom: 10px;
            width: 100%;
          }
          &--email {
            a {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .checkout_review_sidebar {
    border-bottom: 0;
    padding-bottom: 0;
    &_subsection {
      padding: 10px 0;
      border-bottom: 1px solid $color-light-gray;
    }
  }
} /* End SideBar */
/* Select Styles */
.checkout .select-box {
  min-width: 50px;
  width: 95%;
  @include breakpoint($landscape-up) {
    width: auto;
  }
  &__label.error {
    color: $color-red;
  }
} /* End Select Styles */

//Styles for Inputs
#confirm,
#index .salonsearch_bg,
#index .checkout.checkout-single-page {
  select.error {
    color: $color-red;
  }
  .select label span {
    display: none;
    .no-placeholder & {
      display: block;
    }
  }
  .form-item {
    padding: 0;
    position: relative;
    input {
      &.error {
        border: 1px solid red;
      }
    }
    &.checkbox--afterpay {
      @include breakpoint($portrait-up) {
        margin-bottom: 0;
      }
    }
  }
  input[type='checkbox'],
  input[type='radio'] {
    float: left;
    margin: 2px 0;
    width: auto;
    ~ label,
    ~ .label {
      clear: none;
      display: inline-block;
      margin-#{$ldirection}: 10px;
      width: auto;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='password'],
  input[type='tel'],
  input[type='number'],
  input[type='search'],
  input[type='date'],
  input[type='time'],
  select,
  textarea {
    background: $white;
  }
}
//Common Styles for Checkout Index Page
#index .checkout-single-page,
#confirm {
  .valid_marker {
    background-color: transparent;
    color: $color-off-black;
    position: absolute;
    #{$rdirection}: -7px;
    bottom: 10px;
    font-size: 14px;
    display: none;
    @include breakpoint($landscape-up) {
      font-size: 18px;
      // display: block;
    }
  }
  .invalid_marker {
    position: absolute;
    #{$rdirection}: 1px;
    color: red;
    top: 2px;
    font-size: 20px;
    display: none;
  }
  section.checkout-panel {
    padding-bottom: 20px;
  }
  .checkout {
    //Checkout Index Page Header
    &__header {
      padding: 25px 0;
      border-bottom: none;
      text-align: center;
      @include breakpoint($landscape-up) {
        text-align: left;
        padding: 0 0 18px;
      }
      h1,
      h2 {
        text-transform: uppercase;
        margin: 0;
        font-size: 41px;
        letter-spacing: -2px;
      }
    }
    //Styles for Anchor Links
    a,
    a:hover {
      @include anchorlink;
    }
    //Active Panel State
    &__navigation {
      padding: 0 10px;
      font-size: 12px;
      border-top: 1px solid $color-light-gray;
      .links {
        @include swap_direction(margin, 15px 8px 15px 0);
        display: inline-block;
        color: $color-light-grey-text;
        &:last-child {
          &::after {
            content: '';
          }
        }
        &::after {
          @include svg-icon-inline-mask('caret--right');
          content: '';
          background-color: $color-off-black;
          width: 12px;
          height: 12px;
          font-size: 12px;
        }
        &.active {
          color: $color-off-black;
          font-weight: bold;
        }
      }
      @include breakpoint($landscape-up) {
        display: none;
      }
      .mobile_hidden {
        @include breakpoint($medium-up) {
          display: inline-block;
        }
      }
    }
  }
  //Panel Content
  .checkout__content {
    border-top: 1px solid $color-light-gray;
    @include breakpoint($landscape-up) {
      width: 65%;
      padding: 0;
    }
    .checkout-panel {
      @include breakpoint($landscape-up) {
        padding: 0 10px;
      }
      .messages {
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          padding: 0;
        }
      }
      &__content {
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          padding: 0;
        }
      }
      header {
        padding: 0 10px;
        @include breakpoint($landscape-up) {
          margin: 0 0 14px;
          padding: 0;
        }
        h3,
        h2 {
          @include checkout-index-header-mobile;
          @include breakpoint($landscape-up) {
            @include checkout-index-header-pc();
          }
        }
      }
      //Continue Button
      .continue-button-wrapper {
        @include breakpoint($landscape-up) {
          margin: 15px 0;
          float: right;
        }
        input {
          @include continue-button;
          border: none;
        }
      }
      //Panel Finished State
      &.display,
      &.finished {
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: 0;
        @include breakpoint($landscape-up) {
          padding: 0;
          border-bottom: none;
          border-top: none;
        }
        margin: 0;
        header {
          margin: 0;
          display: block;
          @include breakpoint($landscape-up) {
            padding: 0 10px;
            cursor: pointer;
          }
          a.edit {
            min-width: 20px;
            border: none;
            padding: 0;
            margin: 15px 0 0;
            font-size: 18px;
            color: $color-off-black;
            border-bottom: 1px solid $color-off-black;
            height: auto;
            line-height: normal;
            background: none;
            @include breakpoint($landscape-up) {
              @include swap_direction(margin, 15px 0 0 15px);
              float: none;
              position: relative;
              bottom: 4px;
            }
          }
          h2,
          h3,
          h4 {
            @include checkout-index-header-mobile;
            .view-content-button {
              display: none;
              @include breakpoint($landscape-up) {
                display: block;
                #{$rdirection}: 15px;
                top: 45%;
                cursor: pointer;
              }
            }
            @include breakpoint($landscape-up) {
              @include checkout-index-header-pc();
            }
          }
        }
        .checkout-panel__content {
          padding: 0 10px 20px;
          @include breakpoint($landscape-up) {
            padding: 0 10px 20px;
          }
          p {
            margin: 0;
          }
        }
        p.checkout-panel__content-opt {
          margin-top: 10px;
        }
      }
      //Panel Collapsed State
      &.collapsed {
        border-bottom: none;
        @include breakpoint($landscape-up) {
          border-top: none;
        }
        padding: 0;
        margin: 0;
        header {
          margin: 0;
          background: $color-light-linen;
          padding: 20px 10px 15px;
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
          h3 {
            margin: 0;
          }
        }
      }
    }
    .share_sharing_content {
      padding: 16px 0;
      @include breakpoint($medium-up) {
        padding: 10px 0;
      }
      border-top: 1px solid $color-off-black;
      @include breakpoint($medium-up) {
        border-top: 1px solid $color-light-gray;
      }
      .share_sharing {
        &_title {
          font-size: 18px;
          font-weight: bold;
          @include breakpoint($medium-up) {
            font-size: 28px;
            font-weight: normal;
          }
        }
        &_description {
          @include breakpoint($medium-up) {
            width: 63%;
          }
        }
      }
      a {
        border-bottom: none;
        margin-#{$rdirection}: 3%;
        font-size: 23px;
        &::before {
          width: 23px;
          height: 23px;
          background-color: $color-off-black;
        }
      }
    }
  }
}

//Guarantee
.checkout-panel {
  &--guarantee {
    a {
      color: $white;
      display: inline-block;
      text-decoration: underline;
      margin-top: 0;
    }
    header,
    .guarantee-content {
      padding: 0 !important;
    }
  }
}

//Sidebar
#index .checkout.checkout-single-page {
  .checkout__sidebar {
    .checkout-panel {
      header {
        margin: 0;
        h2,
        h3 {
          @include checkout-index-header-mobile;
          padding: 7px 0 4px;
          margin: 15px 0 24px;
          @include breakpoint($landscape-up) {
            @include checkout-index-header-pc();
            padding: 0 0 4px;
            margin: 15px 0 10px;
          }
        }
      }
      &--offer-code {
        @include breakpoint($landscape-up) {
          margin-bottom: 0;
        }
      }
      &--need-help {
        @include breakpoint($landscape-up) {
          border-top: none;
        }
      }
    }
  }
}

#index {
  .header-primary-menu {
    display: none;
  }
  .header-secondary-menu {
    display: block;
  }
  .pg-wrapper.checkout.checkout-single-page .checkout-panel .view-content-container {
    header {
      margin-bottom: 15px;
      padding-#{$ldirection}: 0;
      h3,
      h4 {
        cursor: pointer;
        @include shipping-sub-header();
        .view-content-button {
          position: relative;
          #{$ldirection}: 5px;
          top: 3px;
        }
      }
    }
  }
}

#max-quantity-container {
  h2 {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 15px;
  }
  a {
    border-bottom: 1px solid $color-off-black;
    &:hover {
      text-decoration: none;
    }
  }
}

#viewcart .checkout.viewcart .checkout__sidebar,
#index .checkout.checkout-single-page .checkout__sidebar {
  .checkout-panel--offer-code {
    .offer-code-content {
      &__form-text {
        padding: 0;
        float: left;
        margin-bottom: 20px;
        width: 61%;
        input {
          width: 100%;
        }
      }
      &__form-submit {
        padding: 0;
        width: 28%;
        position: relative;
        bottom: 1px;
        #{$rdirection}: 1px;
        float: left;
      }
    }
  }
}

.viewcart {
  .checkout {
    &__content {
      .checkout-panel {
        .checkout-buttons-content {
          .disabled {
            background: $color-gray;
            border-color: $color-gray;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#index {
  .pg-wrapper {
    .payment-edit-content {
      .afterpay-option-checkbox {
        display: inline;
      }
    }
  }
}

.cboxIframe {
  &.afterpay-overlay {
    #cboxLoadedContent {
      height: 620px !important;
    }
  }
}
