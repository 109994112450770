.store-locator__results-container {
  .store-locator__controls {
    .search-submit {
      letter-spacing: normal;
    }
  }
}

.content-container {
  .cs-page {
    &__content-item {
      .support {
        li {
          line-height: normal;
        }
      }
    }
  }
}
/* Phone Order App */
#csr_header {
  a {
    display: block;
    border-bottom: none;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
  }
  #csr_info_box {
    border-right: 1px solid #ff0000;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid #ff0000;
  }
  .csr_table {
    td {
      vertical-align: middle;
      padding: 0 20px;
    }
    #csr_dragger {
      cursor: move;
      width: 40px;
      padding: 0;
      background: #ff0000;
    }
  }
}

.waitlist-form {
  &__errors {
    color: $color-red;
  }
}

.welcome-15 {
  .site-email-signup {
    &__success {
      padding-top: 1.6em;
    }
    &__success-offer {
      padding-bottom: 12px;
    }
    &__success-terms {
      margin-top: 0.6em;
    }
  }
  .popup-offer {
    &__sign-up-form-error-messages {
      max-width: 370px;
    }
  }
}

.signin-overlay {
  .pure-privilege-container {
    .new-account__item {
      display: flex;
      .pure-privilege-form-toggle {
        @include swap_direction(margin, 0 5px 15px 0);
      }
    }
    label.signin-block__checkbox {
      margin-bottom: 0;
    }
    fieldset {
      &.profile {
        &.fs {
          width: 100%;
        }
      }
    }
  }
  .signin-block {
    &__checkboxes {
      .show-password {
        margin-top: 7px;
      }
      .signin-block__field-wrapper {
        clear: both;
        display : flex;
        .accepted-privacy-policy {
          @include swap_direction(margin, 0 5px 40px 0);
        }
        .pc-email-promotions {
          @include swap_direction(margin, 0 5px 10px 0);
        }
      }
    }
    &__checkbox {
      display: flex;
      margin-bottom: 10px;
      .show-password {
        @include swap_direction(margin, 2px 5px 0 0);
      }
    }
  }
  .password-field {
    &__info {
      background-color: $white;
      width: 25%;
      @include breakpoint($medium-down) {
        width: 50%;
        background-color: $white;
      }
    }
  }
}

.product-quickshop {
  .product-sku-price {
    text-align: right;
    &__value.unit_price,
    .product-points {
      float: left;
      text-align: left;
    }
  }
  .product-add-to-waitlist {
    background: #392720;
    color: #fbf8f6;
    border-color: #fbf8f6;
  }
}
/* Inventory Visibility */
.spp__container {
  .instore-inventory-container {
    .product-store-check {
      &__inline--container {
        border: 0;
      }
    }
    .find-in-store {
      border: 1px solid $color-off-black;
      width: 100%;
      padding: 5px 0;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
}

.product-full {
  .instore-inventory-container {
    .inline-container {
      &__controls {
        margin-top: 30px;
      }
    }
  }
  .location-controls {
    &__distance--select {
      margin-#{$ldirection}: 7px;
    }
  }
  .product-shade-picker {
    float: #{$ldirection};
    width: 100%;
  }
  .product-add-to-waitlist {
    top: 2px;
    position: relative;
  }
  .product-points {
    float: none;
  }
  .product-sku-price {
    text-align: #{$ldirection};
  }
  .afterpay-paragraph {
    font-size: 15px;
  }
}
