// Customer & Services Styles

#bt_settings div {
  &.bt-content {
    border: 1px solid black;
    padding: 15px;
    background-color: white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    border-color: #392720;
    border-width: 2px;
    border-radius: 6px;
    ul {
      margin: 0;
      li {
        list-style-type: none;
      }
      &.slide-toggle {
        position: relative;
        overflow: hidden;
        width: 525px;
        background: transparent url(/media/images/cookies/all-off.png) 50% 100% no-repeat;
        background-size: contain;
        li {
          cursor: pointer;
          float: left;
          width: 175px;
          height: 149px;
          &.middle {
          }
          &.selected {
          }
          span {
            display: block;
            font-weight: bold;
            text-align: center;
            margin: 5px;
            display: none;
          }
        }
      }
    }
    div {
      &.bt-intro {
        h2 {
          text-transform: none;
          color: #392720;
        }
        div.bt-intro-copy p {
          margin-top: 1em;
        }
      }
      &.slide {
        position: relative;
        overflow: hidden;
        width: 525px;
        margin: 20px auto;
      }
      &.mask {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 0;
        border-right: 1px solid #eee;
        ul.slide-toggle {
          background-image: url(/media/images/cookies/all-on.png);
        }
        div.drag {
          position: absolute;
          bottom: 12px;
          right: 70px;
          width: 26px;
          height: 11px;
          background: transparent url(/media/images/cookies/icon_arrow.png) 50% 100% no-repeat;
        }
      }
    }
    .bt-privacy-info {
      display: none;
      overflow: hidden;
      &.selected {
        display: block;
      }
      h3 {
        padding: 1em 1em 1em 40px;
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: #f9f9ef;
      }
      div {
        width: 48%;
        margin-right: 2%;
        float: left;
        &.bt-privacy-will {
          h3 {
            background-image: url(/media/images/cookies/icon_safe_lock.png);
          }
          ul li {
            background-image: url(/media/images/cookies/icon_safe_thick.png);
          }
        }
        &.bt-privacy-willnot {
          h3 {
            background-image: url(/media/images/cookies/icon_unsafe_lock.png);
          }
          ul li {
            background-image: url(/media/images/cookies/icon_unsafe_thick.png);
          }
        }
        ul {
          list-style-type: none;
          padding-left: 0;
          margin: 1em;
          li {
            margin: 0.8em 0;
            padding-left: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &.bt-content-mobile {
    div {
      &.mask {
        ul.slide-toggle {
          background-image: url(/media/images/cookies/all-on.png);
        }
        div.drag {
          right: 36px;
          right: 30px;
          bottom: 11px;
        }
      }
      &.slide {
        margin: 20px auto;
        width: 288px;
        width: 246px;
      }
    }
    ul.slide-toggle {
      background-image: url(/media/images/cookies/all-off.png);
      width: 288px;
      width: 246px;
      li {
        width: 96px;
        height: 159px;
        width: 82px;
        height: 136px;
      }
    }
    .bt-privacy-info div {
      width: 100%;
      float: none;
    }
  }
  &.bt-content-desktop {
    padding: 2em;
    height: 100%;
  }
}
/* cookie notice */

/* CUSTOM POSITIONING */
#bt_notification {
  font-size: 12px;
  line-height: 16px;
  h4 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
  .bt-bottom-right {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 24.5em;
    padding: 10px !important;
    border: 10px solid $color-off-black;
  }
  .bt-bottom-right,
  .bt-bottom-full {
    z-index: 10000;
    background-color: $white;
  }
  .bt_contentarea {
    margin-top: 10px;
  }
  .bt-bottom-full {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 15px;
  border: 1px solid white;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  padding: 10px;
}

#bt_notification {
  .bt-pp-section {
    margin-bottom: 20px;
    a {
      color: $color-off-black;
      text-transform: none;
      text-decoration: underline;
    }
  }
  .btn-wrap {
    margin-top: 10px;
    a {
      width: 100% !important;
      font-weight: normal;
      height: auto !important;
    }
  }
  div.no-pad {
    padding: 0px;
  }
}

.bt-close-link {
  .icon--remove:before {
    color: $white;
  }
}

.overlay-close-container {
  position: absolute;
  top: 8px;
  right: 10px;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
}

.tealium {
  display: none !important;
}
