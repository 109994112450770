/* Samples Page UI */
.checkout-panel--samples {
  .checkout-panel__header {
    text-align: center;
    @include breakpoint($landscape-up) {
      text-align: $ldirection;
    }
    h2 {
      font-size: 36px;
      display: block;
      text-align: center;
      line-height: 1;
      margin-bottom: 0;
      @include breakpoint($landscape-up) {
        font-size: 38px;
        text-align: $ldirection;
      }
    }
  }
  .samples-content {
    &__continue-shopping {
      display: block;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      text-decoration: underline;
      padding-bottom: 50px;
      border-bottom: 1px solid $color-off-black;
    }
    &__free-header {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 20px 15px 0;
    }
    &__free-desc {
      padding: 0 15px;
      @include breakpoint($landscape-up) {
        font-size: 16px;
        padding: 0;
      }
    }
    &__sample-header {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0 0;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        font-size: 24px;
        font-weight: normal;
        margin: 15px 0;
      }
    }
    &__form {
      padding: 15px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    &__button--mobile,
    &__button-group--bottom {
      padding: 0 15px;
      a {
        width: 100%;
      }
      @include breakpoint($landscape-up) {
        padding: 15px 0;
      }
    }
    &__button--mobile {
      a {
        background: $color-off-black;
        color: $white;
        font-weight: bold;
      }
    }
    .product-list-container {
      border-bottom: 1px solid $color-off-black;
    }
    .product-img {
      display: table-cell;
      height: 390px;
      vertical-align: bottom;
      width: 230px;
    }
    @include breakpoint($landscape-down) {
      text-align: center;
    }
    @include breakpoint($landscape-up) {
      &__button-group--top {
        padding: 15px 0;
        border-bottom: 1px solid $color-off-black;
        border-top: 1px solid $color-off-black;
        margin-top: 50px;
      }
      &__button {
        display: inline-block;
        margin-right: 10px;
        &:nth-child(2) {
          float: right;
          margin-right: 0;
        }
      }
      &__continue {
        background: $color-off-black;
        color: $white;
      }
    }
    .product {
      width: 48%;
      float: left;
      margin-bottom: 30px;
      .product-brief__title {
        border-top: 1px solid $color-off-black;
        font-weight: bold;
        padding-top: 10px;
        text-transform: uppercase;
      }
      .skus {
        margin-top: 15px;
        font-weight: bold;
        .sku {
          width: 100%;
          &:hover {
            color: $white;
          }
          &.disabled {
            background: transparent;
            opacity: 0.5;
            a:hover {
              color: $color-off-black;
              background: transparent;
            }
          }
          a {
            text-decoration: none;
            font-size: 14px;
            padding-#{$ldirection}: 0;
            padding-#{$rdirection}: 0;
            @include breakpoint($landscape-up) {
              font-size: 15px;
              padding: 9px 19px 8px;
            }
          }
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
      }
      &.selected {
        .tick-mark {
          padding-#{$rdirection}: 10px;
        }
      }
      @include breakpoint($landscape-up) {
        width: 24.5%;
        padding: 1%;
        margin-right: 0.5%;
        &.selected {
          border: 1px solid $light-brown;
          .sample-select-button {
            border: none;
            padding: 0;
            min-width: 10px;
            &.selected {
              padding-#{$ldirection}: 0;
            }
            &:hover {
              color: $color-off-black;
              background: none;
            }
          }
        }
      }
    }
    .product:nth-child(2n + 1) {
      margin-right: 4%;
      @include breakpoint($landscape-up) {
        margin-right: 0.5%;
      }
    }
    .product:nth-child(4n + 5) {
      @include breakpoint($landscape-up) {
        clear: left;
      }
    }
  }
}
/*
 GWP Samples Page UI
 */
.wp-samples-page {
  .checkout {
    &.samples-page {
      margin: 10px 0 0;
      @include breakpoint($landscape-up) {
        max-width: 100%;
      }
      .checkout-panel--samples {
        padding: 5px 0 75px;
        @include breakpoint($landscape-up) {
          padding-bottom: 65px;
        }
        .samples-content {
          margin-bottom: 0;
        }
      }
    }
  }
  .checkout-panel--samples {
    border-top: 1px solid $light-gray;
    &:first-child {
      border-top: 0;
    }
    .checkout-panel__header {
      text-align: center;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
    h2.checkout-panel__heading {
      font-size: 24px;
      text-align: center;
      @include breakpoint($landscape-up) {
        font-weight: 500;
      }
    }
    .samples-content {
      &__sample-subheader {
        font-size: 14px;
        line-height: 1.14;
        padding-bottom: 10px;
        @include breakpoint($landscape-up) {
          padding-top: 10px;
          text-align: center;
        }
      }
      &__sample-disclaimer {
        text-align: center;
      }
      &__sample-header {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
        text-transform: none;
        @include breakpoint($landscape-up) {
          margin-bottom: 0;
          text-align: center;
        }
      }
      &__button-group--bottom {
        background: $white;
        bottom: 0;
        box-shadow: 0 5px 12px 0 $color-off-black;
        #{$ldirection}: 0;
        padding: 16px;
        position: fixed;
        text-align: center;
        width: 100%;
        @include breakpoint($landscape-up) {
          padding: 10px 0;
        }
        .form-submit {
          font-size: 16px;
          line-height: 1.4;
          padding: 10px;
          width: 100%;
        }
      }
      &__button--checkout {
        @include breakpoint($landscape-up) {
          min-width: 200px;
        }
      }
      &__samples {
        @include breakpoint($landscape-up) {
          margin: auto;
          max-width: 1024px;
        }
      }
      .samples-list {
        border-top: 1px solid $light-gray;
        padding: 10px 16px;
        &:first-child {
          padding-top: 0;
          border-top: 0;
        }
        .product-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .product {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
          }
        }
      }
      .product-list-container {
        border-bottom: 0;
      }
      .product-img {
        display: block;
        height: auto;
        min-height: 240px;
        text-align: center;
        width: auto;
        img {
          max-height: 240px;
        }
      }
      .details {
        border-top: 1px solid $color-off-black;
        padding: 16px;
        text-align: center;
      }
    }
    .product {
      .product-brief__title {
        border-top: 0;
        padding-top: 0;
      }
      .product-size {
        padding: 8px 0;
      }
      .skus {
        margin-top: auto;
        .sku {
          .form-item {
            margin-bottom: 0;
          }
          .sample-select-button {
            padding: 15px 0;
            width: 100%;
            @include breakpoint($landscape-up) {
              padding: 9px 0 8px;
            }
          }
          .selected {
            background: $color-off-black;
            border: 1px solid $color-off-black;
            color: $white;
          }
        }
      }
      &.selected {
        border: 0;
      }
    }
  }
  .site-footer {
    display: none;
  }
}
