/* CONFIRMATION PANEL */

#confirmation-page {
  #guarantee-panel {
    margin: 2em 0;
  }
  .confirmation-panel {
    &__actions {
      margin-#{$ldirection}: 2em;
      margin-bottom: 2em;
      .button {
        background: none repeat scroll 0 0 #666666 !important;
        border-color: rgb(204, 204, 204);
        border-radius: 3px;
        color: $white !important;
        display: inline-block !important;
        height: 2.2em;
        font-size: 14px !important;
        line-height: 25px !important;
        padding: 4px 10px !important;
        text-transform: uppercase !important;
      }
      .button:hover {
        color: $color-off-black !important;
        text-decoration: none;
      }
    }
    h3 {
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 1em;
    }
  }
}

.registration-panel {
  &__content {
    .checkout {
      &__button {
        background: none repeat scroll 0 0 #666666 !important;
        color: $white !important;
        display: inline-block !important;
        font-size: 14px !important;
        font-weight: bold;
        line-height: 25px !important;
        padding: 4px 10px !important;
        text-transform: uppercase !important;
      }
      &__button:hover {
        color: $color-off-black !important;
      }
    }
  }
  &__pre {
    white-space: pre-wrap;
  }
  &__registration-form-container {
    .show-password input {
      vertical-align: middle;
    }
  }
}

#confirm {
  .checkout.checkout--confirmation-page { // Override purposes.
    .checkout__header {
      width: 100%;
      padding: 0 20px;
      @include breakpoint($landscape-up) {
        width: 64%;
        padding: 0 20px 15px;
      }
      .checkout__heading {
        margin-bottom: 5px;
        font-size: 19px;
        font-weight: 700;
        text-align: $ldirection;
        letter-spacing: normal;
        @include breakpoint($landscape-up) {
          font-size: 41px;
          letter-spacing: -2px;
        }
      }
    }
    .email_address_reg {
      border-top: none;
      border-bottom: none;
      padding: initial;
      margin-bottom: 0;
      background: none;
      .checkout-registration__email-address {
        @include breakpoint($mobile-only) {
          display: block;
          font-size: 19px;
        }
      }
    }
  }
  .checkout {
    &__content {
      .checkout-panel {
        .continue-button-wrapper {
          float: none;
          margin: 0;
          input,
          a {
            width: 100%;
            min-width: 50%;
            height: 48px;
            margin-bottom: 0;
            padding: 10px;
            border: 1px solid $color-off-black;
            background-color: $color-off-black;
            color: $white;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0;
            &:hover {
              background-color: $color-off-black;
              color: $white;
            }
            @include breakpoint($portrait-up) {
              width: auto;
            }
          }
        }
        &.refer-friend {
          @include breakpoint($landscape-up) {
            display: flex;
            align-items: center;
            align-content: center;
          }
          .continue-button-wrapper {
            text-align: center;
            @include breakpoint($landscape-up) {
              text-align: $ldirection;
            }
          }
          .refer-friend__image-section {
            padding: 0 0 25px;
            @include breakpoint($landscape-up) {
              padding: 0;
            }
          }
          .refer-friend__content {
            padding-bottom: 0;
            @include breakpoint($landscape-up) {
              @include swap_direction(padding, 0 0 0 20px);
            }
          }
        }
      }
    }
    .checkout__content {
      border: none;
      .checkout-panel {
        &:first-child {
          .checkout-panel__header {
            display: none;
          }
        }
      }
    }
    section { // Override purposes.
      &.checkout-panel {
        margin: 0 10px;
        padding: 20px 10px;
        border-bottom: none;
        @include breakpoint($landscape-up) {
          padding-bottom: 20px;
        }
        &--registration,
        &--pure-privilege-confirmation,
        &--sign-in-confirmation,
        &--aveda-plus-enroll {
          padding: 20px 0;
        }
        &--sign-in-confirmation.has-pp-enrollment-item,
        &--registration.has-pp-enrollment-item,
        &--aveda-plus-enroll.has-pp-enrollment-item {
          padding: 0 0 20px;
          border-top: none;
        }
        &--pure-privilege-confirmation.has-pp-enrollment-item,
        &--pure-privilege-confirmation-errors--container {
          padding: 20px 0 0;
        }
      }
    }
    .checkout-panel {
      &--registration--container,
      &--pure-privilege-confirmation--container,
      &--pure-privilege-confirmation-errors--container,
      &--sign-in-confirmation--container,
      &--aveda-plus-enroll--container {
        padding: 16px;
        background-color: $color-belgian-linen;
        h2 {
          font-size: 28px;
          letter-spacing: normal;
          @include breakpoint($landscape-up) {
            font-size: 41px;
            letter-spacing: -2px;
          }
        }
      }
      &--pure-privilege-confirmation-errors,
      &--pure-privilege-confirmation--container {
        .pure-enroll--intro {
          margin-bottom: 16px;
        }
        .pure-enroll--number {
          font-weight: 700;
        }
        .pure-enroll--content {
          margin: 0;
        }
        ul {
          list-style-type: disc;
          padding-left: 25px;
        }
      }
      &__content {
        padding: initial;
      }
      header {
        margin: 0;
        padding: 0;
        h2,
        h3 {
          margin: 0 0 0.5em;
        }
      }
      &.checkout-panel--email-and-sms-promotions {
        display: none;
      }
      .sign-in-confirmation-content,
      .registration-content {
        fieldset {
          max-width: 100%;
          @include breakpoint($portrait-up) {
            max-width: 65%;
          }
        }
        fieldset.aveda-enroll__form-container {
          max-width: 100%;
          @include breakpoint($landscape-up) {
            max-width: initial;
          }
        }
      }
      .sign-in-confirmation-content {
        .email-address {
          height: auto;
          margin-bottom: 10px;
          div {
            text-transform: uppercase;
            color: $color-gray;
          }
          span {
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
          }
        }
      }
      .registration-content {
        &__create-account {
          padding: 0 5px;
          width: 100%;
          margin-bottom: 20px;
          clear: both;
          @include breakpoint($landscape-up) {
            background-color: $color-brown-bg;
            color: $white;
            padding: 20px 10px;
            position: absolute;
            top: 55px;
            #{$rdirection}: 0;
            text-align: center;
            width: 36.641%;
          }
          .registration-content__heading {
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
        }
        &__pre {
          text-align: $ldirection;
          ul {
            margin-top: 10px;
          }
          li {
            @include swap_direction(margin, 0 0 5px 17px);
            list-style-type: disc;
          }
        }
        &__registration-form-container {
          .registration-content__heading {
            color: $color-gray;
            font-size: 14px;
            margin-bottom: 5px;
          }
          .checkout-registration__email-address {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .sign-in-confirmation-content,
      .registration-content__registration-form-container {
        .checkout-registration__password {
          width: 100%;
          .pc_hidden {
            float: none;
          }
          .note {
            margin-bottom: 0;
            margin-top: 13px;
          }
          .password {
            position: relative;
            font-size: 16px;
            input {
              height: 56px;
              @include swap_direction(padding, 0 38px 0 16px);
              border: 1px solid $color-light-gray;
            }
            label {
              font-size: 16px;
              line-height: 2.5;
              height: 48px;
              margin-bottom: 0;
            }
            .password-eye-icon {
              position: absolute;
              top: 18px;
              #{$rdirection}: 15px;
              cursor: pointer;
              .hide-password-icon,
              .show-password-icon {
                display: block;
                height: 20px;
                width: 22px;
              }
              .hide-password-icon {
                background: url('#{$base-theme-path}img/icons/src/hide_password_icon.svg') no-repeat 0 2px;
              }
              .show-password-icon {
                background: url('#{$base-theme-path}img/icons/src/show_password_icon.svg') no-repeat 0 2px;
                margin-top: 2px;
              }
            }
          }
        }
      }
      &--guarantee {
        .checkout-panel__content {
          margin-bottom: 0;
          padding: 0;
          p {
            margin: 0;
          }
          a {
            color: $black;
            margin-bottom: 0;
            text-decoration: underline;
            border: none;
          }
        }
      }
      &__salon {
        float: $ldirection;
        width: 33.3%;
        &:nth-of-type(even) {
          padding: 0 4px;
        }
        h3,
        h4 {
          font-weight: bold;
        }
        span {
          display: block;
        }
        &--header h3 {
          font-weight: bold;
          padding: 15px 0;
          text-transform: uppercase;
        }
      }
      &__mergeretail {
        float: $ldirection;
        margin-top: 20px;
        margin-bottom: 15px;
        width: 100%;
        h3 {
          font-weight: bold;
        }
        span {
          display: block;
        }
        &--header h3 {
          margin-top: 20px;
          font-weight: bold;
          padding: 15px 0;
          text-transform: uppercase;
        }
      }
      &--carbon-offset {
        padding-bottom: inherit;
      }
      .carbon-offset-confirm {
        &__left {
          border-top-#{$rdirection}-radius: 8px;
          background: $color-light-linen;
          border-top-#{$ldirection}-radius: 8px;
          display: block;
          margin-bottom: 2px;
          padding: 15px;
          width: 100%;
          @include breakpoint($landscape-up) {
            border-top-#{$rdirection}-radius: 0;
            border-bottom-#{$ldirection}-radius: 8px;
            display: inline-block;
            margin-bottom: 0;
            width: 73%;
          }
        }
        &__logo {
          margin-#{$rdirection}: 7px;
          vertical-align: middle;
          width: 18px;
          @include breakpoint($landscape-up) {
            margin-top: 10px;
            vertical-align: super;
            width: 14px;
          }
        }
        &__zip-code {
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          margin-top: 15px;
          width: 90%;
          @include breakpoint($portrait-up) {
            width: 92%;
          }
        }
        &__zip-selected {
          font-weight: bolder;
          margin-#{$ldirection}: 2px;
        }
        &__right {
          background: $color-light-linen;
          border-bottom-#{$rdirection}-radius: 8px;
          border-bottom-#{$ldirection}-radius: 8px;
          display: block;
          text-align: center;
          width: 100%;
          @include breakpoint($landscape-up) {
            border-top-#{$rdirection}-radius: 8px;
            border-bottom-#{$ldirection}-radius: 0;
            display: inline-block;
            width: 25%;
            vertical-align: bottom;
          }
        }
        &__kg {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 0;
          padding: 12px 0;
          @include breakpoint($landscape-up) {
            padding-top: 49px;
            padding-bottom: 0;
          }
        }
        &__coe {
          font-size: 22px;
          font-weight: 400;
          line-height: 1;
          @include breakpoint($portrait-up) {
            font-size: 16px;
            padding-bottom: 26px;
          }
          .confirm-sub {
            font-size: 16px;
            vertical-align: sub;
            @include breakpoint($portrait-up) {
              font-size: 14px;
            }
          }
        }
        &__powered {
          background: $color-satin-linen;
          margin: 0;
          padding: 3px 0;
          @include breakpoint($landscape-up) {
            border-bottom-#{$rdirection}-radius: 8px;
            padding: 5px 0;
          }
        }
        &__footer {
          padding: 16px;
          text-align: $ldirection;
          @include breakpoint($landscape-up) {
            padding: initial;
          }
          &--delivery {
            font-weight: bold;
          }
          &--conditions {
            color: $gray;
            font-size: 11px;
            margin-bottom: 0;
            .carbon-sub {
              font-size: 10px;
              vertical-align: sub;
            }
          }
        }
        .cloverly-logo {
          height: 16px;
          margin-#{$rdirection}: 5px;
          vertical-align: middle;
          width: 90px;
          @include breakpoint($portrait-up) {
            height: 14px;
            width: 70px;
          }
        }
      }
      .confirmation-content {
        &__confirm-text--not-signed-in,
        &__confirm-text--signed-in {
          width: auto;
          font-size: 16px;
          text-align: $ldirection;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            font-size: 19px;
          }
          @include breakpoint($mobile-only) {
            .confirm_text {
              display: initial;
            }
          }
          .link,
          .confirm_text {
            font-weight: bold;
          }
        }
      }
    }
    .create-account.button-wrapper {
      .continue-as-guest {
        display: none;
      }
    }
    .checkout__sidebar {
      border-top: none;
      margin-bottom: 0;
      .checkout-panel--links {
        padding-bottom: 0;
        @include breakpoint($landscape-up) {
          padding-bottom: 20px;
        }
        .links-content {
          margin: 0;
          a.link {
            border-bottom: none;
            display: inline;
          }
        }
      }
    }
    fieldset {
      .accepted-privacy-policy {
        width: 100%;
        a {
          margin: initial;
        }
      }
      .birthday_wrapper {
        max-width: 320px;
      }
      .form-item {
        clear: both;
        float: none;
        height: auto;
        margin-bottom: 20px;
        &.checkout-registration__email-address {
          margin-bottom: 15px;
        }
        &.birthday_signup__birth-month,
        &.birthday_signup__birth-day {
          float: $ldirection;
          clear: none;
          width: 30%;
        }
      }
    }
    .birth_date_reg {
      width: 100%;
      margin-bottom: 10px;
      .select-box__options,
      .select-box__label {
        min-width: 0;
      }
      .select-box__options {
        top: 53px;
      }
      .select-box__label {
        height: 100%;
        line-height: 48px;
        border: 1px solid $color-light-gray;
      }
      .registration-content__birth_date__heading {
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        clear: both;
      }
      h3 {
        clear: both;
      }
      .form-item {
        clear: unset;
      }
      .checkout-registration {
        &__birth-month,
        &__birth-day {
          width: 48%;
          height: 54px;
          margin: 0;
          .select-box {
            height: 100%;
            min-width: 100%;
            font-size: 16px;
          }
        }
        &__birth-day {
          float: $rdirection;
        }
        &__birth-month {
          float: $ldirection;
        }
      }
    }
    .email_optin {
      .label,
      label {
        display: initial;
      }
    }
    .hair_concern_reg {
      h3 {
        margin-top: 10px;
      }
    }
    .aveda-plus-enrollment-section {
      h3 {
        font-size: 20px;
        font-weight: 500;
      }
      .aveda-enroll {
        &__header {
          cursor: pointer;
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
          margin: 0;
          width: 100%;
        }
        &__heading {
          display: block;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          margin: 0;
          padding: 13px 15px;
          text-transform: uppercase;
        }
        &__content {
          .form-item {
            .select-box__label {
              border: 1px solid $color-light-gray;
              height: 50px;
              line-height: 3.7;
              &::after {
                line-height: 3.7;
              }
            }
            .select-box__options {
              top: 50px;
            }
          }
        }
        &__number {
          margin-bottom: 15px;
          font-weight: bold;
        }
        &__form-subheader {
          margin: 15px 0;
          font-weight: bolder;
        }
        &__birth-month,
        &__birth-day {
          display: inline-block;
          @include breakpoint($portrait-up) {
            width: 35%;
          }
        }
        &__birth-month--field,
        &__birth-day--field {
          width: 100%;
          @include breakpoint($portrait-up) {
            width: 95%;
          }
        }
        &__birth-info {
          font-weight: bolder;
          margin-bottom: 5px;
        }
      }
      .email_optin {
        margin-bottom: 10px;
      }
      .error-message {
        background: $color-error;
        padding: 5px;
        color: $white;
      }
      .success-message {
        background: $color-green;
        padding: 5px;
        color: $white;
      }
    }
    .social-login {
      &__container,
      &__email-opt-in {
        padding: 0;
      }
    }
  }
}
