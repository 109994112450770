/* SHIPPING PANEL */
#confirm,
#index .checkout.checkout-single-page {
  fieldset {
    @include clearfix;
    border: 0;
    margin: 0;
    padding: 0;
    clear: both;
    width: 100%;
    max-width: 664px;
    margin-left: 0;
    .form-item {
      input[type='text'],
      input[type='email'],
      input[type='url'],
      input[type='password'],
      input[type='tel'],
      input[type='number'],
      input[type='search'],
      input[type='date'],
      input[type='time'],
      select,
      textarea {
        width: 100%;
        background: $white;
      }
      .button {
        margin-left: 1px;
        width: 100%;
        cursor: pointer;
        label {
          display: block;
          cursor: pointer;
        }
      }
      .select-box__label {
        height: 35px;
        line-height: 35px;
      }
    }
  }
  .checkout-panel {
    &--shipping {
      @include breakpoint($landscape-up) {
        border-bottom: none;
        border-top: 1px solid $color-light-gray;
      }
      .single-message {
        padding-top: 0;
        margin-bottom: 0;
      }
      header {
        margin-bottom: 0;
        p {
          margin: 10px 0 0 0;
          @include breakpoint($landscape-up) {
            margin: 0;
          }
        }
      }
      &.collapsed {
        header {
          margin-bottom: 15px;
        }
      }
      &-edit-address {
        .show_less_address,
        .show_more_address,
        a {
          @include anchorlink;
          cursor: pointer;
        }
        .select-address {
          padding: 0;
          .select-menu {
            height: auto;
            margin-bottom: 0;
            .address-options {
              float: left;
              height: auto;
              width: 48%;
              @include breakpoint($landscape-up) {
                width: 33.3%;
              }
              &:nth-child(odd) {
                margin-right: 10px;
                @include breakpoint($landscape-up) {
                  margin-right: 0;
                }
              }
              &__links {
                margin-bottom: 15px;
              }
            }
          }
          .address-options {
            height: auto;
            &__links {
              a {
                cursor: pointer;
              }
            }
          }
        }
        .address-form {
          .form-item {
            width: 100%;
            float: none;
            @include breakpoint($landscape-up) {
              width: 50%;
              float: left;
            }
          }
          &__phones {
            @include breakpoint($landscape-up) {
              p {
                float: right;
                margin-right: 15px;
              }
            }
          }
          .default-shipping {
            margin: 0 0 20px;
            height: auto;
            label {
              margin-bottom: 15px;
              &:before {
                margin-top: -5px;
              }
            }
          }
          .name-fields {
            @include breakpoint($landscape-up) {
              .first-name,
              .last-name {
                width: 49%;
              }
              .first-name {
                margin-right: 12px;
              }
            }
          }
          .addresses {
            @include breakpoint($landscape-up) {
              .form-item {
                width: 100%;
              }
            }
          }
          .phone-1 {
            @include breakpoint($landscape-up) {
              width: 49%;
            }
          }
          &__city-state-zip {
            .city,
            .postal-code {
              width: 48%;
              float: left;
              @include breakpoint($landscape-up) {
                width: 24%;
                float: left;
              }
            }
            .postal-code {
              margin-right: 10px;
              @include breakpoint($landscape-up) {
                margin-right: 6px;
              }
            }
            .state {
              clear: both;
              .select-box {
                width: 100%;
              }
              @include breakpoint($landscape-up) {
                margin-left: 13px;
                width: 49%;
                clear: none;
              }
            }
          }
        }
      }
      //Gift Options
      .gift-options-content {
        header {
          margin: 0;
          padding: 0;
          h3 {
            @include breakpoint($landscape-up) {
              @include shipping-sub-header();
            }
          }
        }
        .checkout-panel__content {
          padding: 0;
          display: none;
        }
        &__container {
          .giftwrap__is-gift {
            height: auto;
            float: none;
            margin-bottom: 0;
            width: auto;
            label {
              width: 90%;
              &:before {
                margin-top: -5px;
              }
            }
          }
          p {
            @include subheader-content;
          }
          .gift-message__container {
            h3 {
              @include shipping-sub-header();
              display: none;
            }
            img {
              margin-bottom: 20px;
            }
            .chars-remaining {
              color: $color-gray;
              margin: 20px 0px 10px;
            }
            .card-message-to,
            .card-message-from {
              display: none;
            }
            .card-message-to,
            .card-message-from,
            .card-message {
              width: 100%;
              @include breakpoint($landscape-up) {
                width: 49%;
                float: left;
              }
            }
            .card-message-to {
              @include breakpoint($landscape-up) {
                margin-right: 12px;
              }
            }
            .card-message {
              height: auto;
              margin-bottom: 0;
              @include breakpoint($landscape-up) {
                width: 100%;
              }
              textarea {
                border: 1px solid $color-off-black;
                background: none;
                color: $color-off-black;
                font-size: 1em;
                padding: 15px;
              }
            }
          }
        }
      }
      &.finished {
        header {
          @include breakpoint($landscape-up) {
            background: $color-light-linen;
            margin-bottom: 15px;
          }
        }
        .shipping-address-display-content {
          padding-bottom: 10px;
          @include breakpoint($landscape-up) {
            margin: 0 20px 20px 0;
          }
          &__heading {
            @include checkout-index-header-mobile;
            @include breakpoint($landscape-up) {
              @include shipping-sub-header();
            }
          }
        }
        .checkout-panel__content {
          @include breakpoint($landscape-up) {
            float: left;
            width: 45%;
          }
        }
        .gift-options-display-content {
          &__heading {
            @include checkout-index-header-mobile;
            @include breakpoint($landscape-up) {
              @include shipping-sub-header();
            }
          }
          &__options {
            @include breakpoint($landscape-up) {
              margin-bottom: 15px !important;
            }
          }
          p {
            span {
              display: block;
              margin-bottom: 5px;
              word-break: break-word;
            }
          }
        }
      }
    }
    //Newsletter Section
    &--email-and-sms-promotions {
      border-bottom: none;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      a {
        display: inline-block;
        margin: 5px 0 0 30px;
        color: $color-gray;
        @include breakpoint($landscape-up) {
          color: $color-off-black;
          border-bottom: 1px solid $color-gray;
        }
      }
      p {
        @include subheader-content;
        @include breakpoint($landscape-up) {
          font-size: 18px;
        }
      }
      .checkout-panel__content {
        padding: 0;
        display: none;
        &--email-optin {
          display: block;
        }
      }
      header {
        margin: 0;
        padding: 0;
        h3 {
          @include breakpoint($landscape-up) {
            @include shipping-sub-header();
          }
        }
      }
      .section-email-promotions {
        .form-item {
          height: auto;
          @include breakpoint($landscape-up) {
            margin-bottom: 5px;
          }
          p.emailPro_label_content {
            font-size: 14px;
          }
          a {
            margin: 0;
            text-decoration: none;
            @include breakpoint($landscape-up) {
              border-bottom: 1px solid $color-off-black;
              text-transform: uppercase;
            }
            &.link {
              display: none;
            }
          }
          a.customer-service-ca-rb {
            text-transform: capitalize;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            left: -21px;
            position: relative;
          }
          label {
            &.field_label {
              width: 90%;
            }
            &:before {
              margin-top: -5px;
            }
          }
        }
      }
      .section-sms-promotions {
        .form-item.radio {
          height: auto;
          margin-bottom: 20px;
          span a {
            display: inline;
            margin: 0;
            &:hover {
              text-decoration: none;
            }
          }
          label {
            width: 90%;
            &:before {
              margin-top: -5px;
            }
          }
        }
        .sms-mobile {
          .form-item {
            width: 100%;
            &.sms-mobile-carrier {
              display: none;
            }
            &--full {
              height: auto;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.delete-confirm-overlay {
  .address-delete {
    &__header {
      @include breakpoint($landscape-up) {
        font-size: 31px;
      }
    }
    &__link {
      border-bottom: 1px solid $color-off-black;
      cursor: pointer;
      margin-right: 15px;
      text-transform: uppercase;
      &:hover {
        color: $color-off-black;
        text-decoration: none;
      }
    }
  }
}
