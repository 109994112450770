// @toto check and cleanup color variables
// make generic
// example : $color_mountain_mist_approx (ew)

/* FAVORITES PANEL & PAST PURCHSES PANEL */

//original selectors
//#past-purchases-panel ul.product-list .product, #favorites-panel ul.product-list .product
@mixin product {
  border-spacing: 0.4em;
  height: 12em;
  border-bottom: 1px dotted $color-gray;
  display: block;
  width: 100%;
}

//original selectors
//#past-purchases-panel .dot-pager, #favorites-panel .dot-pager, #samples-panel .dot-pager
@mixin dot_pager {
  font-size: 3em;
  text-align: center;
  cursor: pointer;
  line-height: 1em;
}

#past-purchases-panel {
  .past-purchases-item.product {
    height: 80px;
  }
  h3 {
    margin-bottom: 1em;
  }
  ul {
    &.product-list {
      .product {
        @include product;
        &:last-child {
          border: none;
        }
      }
      > li {
        &.deactivate {
          color: $color-light-gray;
        }
        h4 {
          margin-top: 0.5em;
          font-size: 1em;
        }
      }
    }
    &.skus {
      margin-top: 1em;
      li {
        margin-bottom: 0.5em;
      }
    }
  }
  .swatch {
    float: left;
    margin-right: 0.3em;
  }
  .product-img {
    max-width: 108px;
    float: left;
    text-align: center;
    img {
      width: auto;
    }
    a {
      clear: left;
    }
  }
  .details {
    margin-left: 2em;
  }
  .add-button {
    display: none;
  }
  .no-thanks {
    display: none;
  }
  footer {
    display: none;
  }
  .shades-list {
    float: left;
    clear: both;
  }
  .shade {
    float: left;
  }
  .dot-pager {
    @include dot_pager;
    .cycle-pager-active {
      color: $color-red;
    }
  }
  #cycle-next {
    float: right;
  }
  .product .price {
    width: 100%;
  }
}

#favorites-panel {
  .favorite-item.product {
    height: 100px;
  }
  h3 {
    margin-bottom: 1em;
  }
  ul {
    &.product-list {
      .product {
        @include product;
        &:last-child {
          border: none;
        }
      }
      > li {
        &.deactivate {
          color: $color-light-gray;
        }
        h4 {
          margin-top: 0.5em;
          font-size: 1em;
        }
      }
    }
    &.skus {
      margin-top: 1em;
      li {
        margin-bottom: 0.5em;
      }
    }
  }
  .swatch {
    float: left;
    margin-right: 0.3em;
  }
  .product-img {
    max-width: 108px;
    float: left;
    text-align: center;
    img {
      width: auto;
    }
    a {
      clear: left;
    }
  }
  .details {
    margin-left: 2em;
  }
  .add-button {
    display: none;
  }
  .no-thanks {
    display: none;
  }
  footer {
    display: none;
  }
  .shades-list {
    float: left;
    clear: both;
  }
  .shade {
    float: left;
  }
  .dot-pager {
    @include dot_pager;
    .cycle-pager-active {
      color: $color-red;
    }
  }
  #cycle-next {
    float: right;
  }
  .product .price {
    width: 100%;
  }
}

#samples-panel {
  .dot-pager {
    @include dot_pager;
    .cycle-pager-active {
      color: $color-red;
    }
  }
  #cycle-next {
    float: right;
  }
}

.samples-panel input.sample-select {
  float: left;
  margin-right: 0.3em;
  float: left;
  margin-right: 0.3em;
}

.tab-pane .cycle-page {
  width: 100%;
}
