#index .checkout.checkout-single-page .esalon {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 10px;
  @include breakpoint($landscape-up) {
    padding: 0;
  }
  &__stylist_name {
    display: block;
    margin-bottom: 12px;
  }
  &__heading {
    @include checkout-index-header-mobile;
    cursor: pointer;
    span {
      position: relative;
      top: 3px;
    }
    @include breakpoint($landscape-up) {
      @include shipping-sub-header();
    }
  }
  &__description {
    margin-bottom: 7px;
  }
  &__field-container {
    .form-item {
      width: 100%;
      @include breakpoint($small-down) {
        margin-top: 10px;
      }
    }
    a {
      margin-bottom: 10px;
      display: inline-block;
      text-decoration: underline;
    }
    span.label {
      display: block;
      margin-bottom: 20px;
    }
  }
  &__form-item {
    &--checkbox {
      margin-top: 20px;
    }
    .select-box,
    &--salon-menu {
      width: 100%;
    }
  }
  .link {
    text-transform: uppercase;
  }
}

#index .checkout.checkout-single-page .esalon {
  &__form-item {
    &--checkbox {
      input[type='checkbox'] ~ label {
        width: 90%;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    &--checkbox-share {
      input[type='checkbox'] ~ label {
        display: inline;
      }
    }
  }
  &__no-share,
  &__form-container-share {
    margin-left: 10px;
  }
}

.salon-search {
  &-locator {
    .form-item {
      input,
      select {
        width: 100%;
        background: $white;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
      label {
        font-size: 14px;
      }
    }
    .or_text {
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
  }
  &__results {
    background: $white;
    width: 100%;
    margin: 20px 0 0 0;
  }
  &__submit {
    margin-top: 10px;
  }
  /* More Salon Overlay */
  &__instructions {
    width: 100%;
  }
  #error_display {
    color: $color-red;
    margin-bottom: 15px;
  }
}
