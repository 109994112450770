#index .checkout-single-page {
  .checkout-panel--links {
  }
}

#cboxLoadedContent {
  .cs-page {
    &__navigation {
      display: none;
    }
    &__content {
      width: 100%;
      #returns_policy {
        #section1,
        #section2,
        #section3,
        #section5 {
          display: none;
        }
      }
      #shipping_handling {
        #section1,
        #section3 {
          display: none;
        }
      }
      #tax_info {
        #section1,
        #section2,
        #section3,
        #section4 {
          display: none;
        }
      }
    }
  }
}
