/* ORDER SUMMARY PANEL */
$font: 'MetaMediumRoman';

.checkout-panel--order-summary {
  @include checkout-panel--order-summary;
  .afterpay-learnmore {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    background: $color-off-black;
    color: $white;
    padding-#{$rdirection}: 1px;
    font-size: 10px;
    font-family: $font;
    line-height: 1.4;
    text-transform: lowercase;
    text-align: center;
  }
  .content-full {
    width: 100%;
  }
}

#index .checkout-single-page {
  .checkout__sidebar .checkout-panel--order-summary {
    margin: 0;
    padding: 0;
    @include breakpoint($landscape-up) {
      border-top: none;
    }
    header {
      margin: 0;
      padding: 0;
    }
    .order-summary-content {
      &__value {
        width: 25%;
      }
      .estimated-delivery-date {
        margin-bottom: 1em;
      }
      &__label {
        width: 75%;
        &.shipping {
          margin-bottom: 5px;
        }
        .ship-method {
          margin-bottom: 0;
          margin-top: -7px;
          height: auto;
          min-height: 35px;
          &__information {
            margin: 5px 0;
          }
          .js-select-box-label:after {
            background-color: #fbf8f6;
            width: 41px;
            text-align: center;
            margin-right: -10px;
          }
        }
        &--shipping {
          .select-box {
            width: 200px;
            option {
              display: none;
            }
          }
        }
      }
    }
  }
}
