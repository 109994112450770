#index .checkout.checkout-single-page .pure-privilege {
  padding: 0 10px;
  @include breakpoint($landscape-up) {
    padding: 0;
  }
  &__name {
    margin-bottom: 20px;
  }
  &__edit {
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
    text-decoration: underline;
  }
  &__heading {
    @include checkout-index-header-mobile;
    @include breakpoint($landscape-up) {
      @include shipping-sub-header();
    }
  }
  &__form-subheader {
    padding-top: 10px;
    @include breakpoint($landscape-up) {
      padding-top: 6px;
      clear: both;
      margin: 10px 0;
    }
  }
  &__form-container {
    .existing-address {
      .checkout-panel__subheader {
        display: block !important;
      }
      .address-edit {
        position: static !important;
      }
      .address-content {
        margin: 12px 0 !important;
        display: block !important;
      }
    }
    a {
      text-decoration: underline;
      display: block;
    }
    .form-item {
      width: 100%;
      margin-bottom: 12px;
      @include breakpoint($landscape-up) {
        width: 49%;
        float: left;
        margin: 6px 0;
        line-height: 2;
      }
      .error {
        color: $color-red;
        .select-box__label {
          border: 1px solid $color-red;
        }
      }
      &.pure-privilege__form-item--privilege-number {
        @include breakpoint($landscape-up) {
          margin-bottom: 20px;
        }
        &.hidden {
          & + .pure-privilege__form-item--where-to-find {
            margin-left: 0;
          }
        }
      }
    }
    input[type='checkbox'] ~ label,
    input[type='checkbox'] ~ .label {
      width: 90%;
    }
  }
  &__form-item {
    &--last-name,
    &--where-to-find {
      &.form-item {
        @include breakpoint($landscape-up) {
          margin-left: 11px;
        }
      }
    }
    &--where-to-find {
      margin-top: 11px;
    }
  }
  &__checkbox-wrapper {
    input[type='checkbox'] ~ label {
      width: 90%;
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
  }
  &__cert-number-text {
    margin-bottom: 10px;
    clear: both;
  }
  .link {
    text-transform: uppercase;
  }
  &-enrollment {
    &__form-item {
      label a {
        // to remove "block" display of links within label tags
        display: inline;
      }
      &--home-phone-opt-in,
      &--email-opt-in,
      &--sms-opt-in,
      &--mobile-phone,
      &--birth-month,
      &--terms {
        clear: both;
      }
      &--home-phone-opt-in,
      &--email-opt-in,
      &--sms-opt-in {
        width: 100% !important;
      }
      &--sms-opt-in {
        height: 130px;
        @include breakpoint($landscape-up) {
          height: 60px;
        }
      }
      &--birth-day,
      &--birth-month,
      &--birth-year,
      &--sex {
        margin-top: 2px;
      }
      &--birth-day,
      &--birth-month,
      &--birth-year {
        @include breakpoint($landscape-up) {
          width: 24% !important;
        }
      }
      &--sex {
        @include breakpoint($landscape-up) {
          width: 20% !important;
        }
        height: auto;
        position: relative;
        label {
          display: none;
        }
      }
      &--terms {
        width: 100% !important;
        @include breakpoint($landscape-up) {
          margin-bottom: 0px;
        }
      }
      &--cancel {
        float: left;
        width: 49%;
      }
      &--submit {
        float: right;
        @include breakpoint($landscape-up) {
          position: relative;
          z-index: 2;
          top: 80px;
          float: right !important;
        }
      }
      input[type='text'] {
        width: 95%;
      }
    }
    &__birth-date-gift-label {
      clear: both;
      margin-top: 10px;
      padding-top: 10px;
      text-transform: uppercase;
    }
    &__cancel {
      display: block;
      margin-top: 10px;
    }
    &__submit {
      float: right;
      margin: 0 !important;
      line-height: 2;
      min-width: 210px;
    }
  }
  &__field-container {
    margin-top: 10px;
    .pure-privilege-enrollment__email-contianer {
      clear: both;
      display: none;
    }
    .pure-privilege-enrollment__form-item {
      input[type='text'] {
        width: 95%;
      }
    }
  }
  &-boutique {
    &.checkout-panel {
      @include breakpoint($landscape-up) {
        padding-top: 60px;
      }
    }
  }
}
/* pure privilege info popup */
.pure-privilege-popup {
  font-size: 14px;
  padding: 40px 30px 15px;
  margin-bottom: 60px;
  ul li {
    margin-bottom: 5px;
  }
  &__header {
    font-size: 28px;
    line-height: 1.07;
    font-weight: 300;
    letter-spacing: -0.005em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}

.pure_privilege-enrollment-container {
  .messages {
    display: none !important;
  }
}
