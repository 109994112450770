#index .checkout.checkout-single-page {
  .checkout-panel {
    &--review {
      @include breakpoint($landscape-up) {
        border-bottom: none;
        padding: 0;
      }
      .checkout-panel--email-and-sms-promotions {
        display: none;
      }
      .form-item.checkbox {
        display: none;
      }
      .messages {
        padding: 0 10px 10px;
        line-height: 18px;
      }
      header {
        margin: 0;
        @include breakpoint($landscape-up) {
          padding: 0 10px;
          background: $color-light-linen;
          margin-bottom: 15px;
        }
      }
      .checkout-panel__content {
        @include breakpoint($landscape-up) {
          padding: 0 10px;
        }
      }
      .warning-message {
        color: $color-off-black;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: normal;
      }
      .review-terms-privacy {
        font-size: 16px;
        margin-top: 10px;
        .terms_privacy {
          text-decoration: underline;
        }
      }
    }
    .submit {
      margin-top: 15px;
      @include breakpoint($landscape-up) {
        margin: 20px 0;
      }
      input {
        @include continue-button;
      }
      input[disabled='disabled'] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
