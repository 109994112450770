@mixin prod-items() {
  margin-bottom: 10px;
  float: left;
  width: 62%;
}

#index .checkout.checkout-single-page {
  .checkout__sidebar .checkout-panel--viewcart {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $light-gray;
    background: none;
    overflow: auto;
    header {
      margin: 0;
      h2,
      h3 {
        float: left;
        margin: 15px 0 5px;
        padding: 0;
      }
    }
    .item-count {
      float: right;
      font-size: 14px;
      margin: 15px 0;
      font-weight: bold;
      text-transform: uppercase;
      span.icon {
        cursor: pointer;
      }
      .edit {
        border: none;
        border-bottom: none;
        text-decoration: underline;
        font-size: 14px;
        min-width: auto;
        padding: 0;
        margin-right: 5px;
        @include breakpoint($landscape-up) {
          margin: 22px 5px 0 0;
        }
      }
    }
    .cart-items {
      clear: both;
      &__item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $light-gray;
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }
        &--thumb {
          float: left;
          width: 32%;
          min-height: 215px;
          margin-right: 10px;
        }
        &--desc {
          font-weight: bold;
          text-transform: uppercase;
          @include prod-items();
        }
        &--total {
          @include prod-items();
          .cart-items__item--product-price {
            text-decoration: line-through;
          }
        }
        &--qty {
          @include prod-items();
        }
        .hazmat {
          @include prod-items();
          color: $color-red;
        }
      }
      .view-detail {
        @include prod-items();
        &__content {
          display: none;
        }
      }
    }
  }
}
